import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/functions";

var firebaseConfig = {
  apiKey: "AIzaSyBQxN5hUH9HCpvmFZ7rmeYPMprdle_3GsQ",
  authDomain: "juicemind-a9d9e.firebaseapp.com",
  projectId: "juicemind-a9d9e",
  storageBucket: "juicemind-a9d9e.appspot.com",
  messagingSenderId: "1043717487308",
  appId: "1:1043717487308:web:60faa200f20f45cfa0e069",
  measurementId: "G-FGRLNTRZ5L",
  databaseURL: "https://juicemind-a9d9e-default-rtdb.firebaseio.com",
};
const app = firebase.initializeApp(firebaseConfig);

let functionsTemp = firebase.functions();

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  console.log("in development");
  firebase.functions().useEmulator("localhost", "5001");
} else {
  console.log("in prod");
}

export const db = firebase.firestore();
//export const ref = firebase.firestore().collection("users"); //added
export const functions = functionsTemp;
export const realtimeDb = firebase.database();
export const auth = firebase.auth();
export default firebase;
