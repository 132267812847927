import React, { useCallback, useEffect, useState } from "react";

import {
  CheckCircle,
  Close,
  Delete,
  DoNotDisturbOn,
  Password,
} from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  Modal,
  IconButton,
} from "@mui/material";
import styles from "./GameRoomsTable.module.css";
import { db, realtimeDb } from "../../../utils/firebase";
import { useHistory } from "react-router-dom";

export default function GameRoomsTable() {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [gameRoomIdToDelete, setGameRoomIdToDelete] = useState();
  const [gameRooms, setGameRooms] = useState([]);

  const [search, setSearch] = useState("");

  const [lastVisible, setLastVisible] = useState();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // New state to track if more data is available

  const history = useHistory();

  const gameRoomClicked = (gameRoom) => {
    console.log("gameRoom", gameRoom);
    history.push("/quizzes/gameRoom/" + gameRoom.id);
  };

  const deleteClicked = async () => {
    console.log("we should be deleting:", gameRoomIdToDelete);
    setShowDeleteModal(false);

    if (!gameRoomIdToDelete || gameRoomIdToDelete.length <= 0) {
      return;
    }
    realtimeDb
      .ref("gameRooms/" + gameRoomIdToDelete)
      .remove()
      .then(() => {
        console.log("Child node removed successfully");
      })
      .catch((error) => {
        console.error("Error removing child node:", error);
      });
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    let query = db
      .collection("gameRooms")
      .orderBy("createdAt", "desc")
      .limit(50);

    // Check if there's a search term for the email
    if (search.trim() !== "") {
      // Adjust the query to filter by exact email match
      query = db.collection("gameRooms").where("email", "==", search.trim());
      console.log("gameRooms ==", search.trim());
    } else if (lastVisible) {
      // Apply pagination for non-search queries
      query = query.startAfter(lastVisible);
    }

    query
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length < 50) {
          setHasMore(false); // If fewer documents than the limit are returned, no more data is available
        } else {
          setHasMore(true);
        }
        const lastVisibleDoc =
          querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (search.trim() !== "") {
          // For searches, replace the current users list with the new results
          setGameRooms(items);
        } else {
          // For pagination, append the new results
          setGameRooms((prev) => [...prev, ...items]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  }, [lastVisible, hasMore, search]);

  useEffect(() => {
    const main = async () => {
      setGameRooms([]);
      fetchData();
    };
    main();
  }, [search]);

  // const shortenedGameRooms = gameRooms.slice(0, 300);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <input
          placeholder="Search by Email"
          style={{
            width: "100%",
            border: "1px solid lightgrey",
            borderRadius: "10px",
            fontSize: "24px",
            padding: "10px",
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{"Started"}</TableCell>
                <TableCell>{"Name"}</TableCell>
                <TableCell>{"Email"}</TableCell>
                <TableCell>{"Quiz"}</TableCell>
                <TableCell>{"Unit"}</TableCell>
                <TableCell>{"Topic"}</TableCell>
                <TableCell>{"Date played"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {gameRooms.map((gameRoom) => (
                // shortenedGameRooms.map((gameRoom) => (
                <TableRow
                  className={styles.tableRow}
                  onClick={() => gameRoomClicked(gameRoom)}
                >
                  <TableCell>
                    <IconButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowDeleteModal(true);
                        setGameRoomIdToDelete(gameRoom.id);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {gameRoom.state != "lobby" ? (
                      <CheckCircle style={{ color: "green" }} />
                    ) : (
                      <DoNotDisturbOn style={{ color: "grey" }} />
                    )}
                  </TableCell>
                  <TableCell>{gameRoom.teacherName}</TableCell>
                  <TableCell>{gameRoom.teacherEmail}</TableCell>
                  <TableCell>{gameRoom.quizName}</TableCell>
                  <TableCell>{gameRoom.quizUnitNumber}</TableCell>
                  <TableCell>{gameRoom.quizTopicNumber}</TableCell>

                  <TableCell>
                    {gameRoom.createdAt
                      ? new Date(gameRoom.createdAt).toLocaleString()
                      : gameRoom.updateTimeInMilliseconds
                      ? new Date(
                          gameRoom.updateTimeInMilliseconds
                        ).toLocaleString()
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {hasMore && (
              <button onClick={fetchData} disabled={loading}>
                {loading ? "Loading..." : "Load More"}
              </button>
            )}
          </Table>
        </TableContainer>
      </Paper>
      <Modal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            padding: "100px",
            backgroundColor: "white",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <div
            className={styles.closeButton}
            onClick={() => setShowDeleteModal(false)}
          >
            <Close />
          </div>
          <div style={{ fontSize: "40px", margin: "40px" }}>Are you sure?</div>
          <div style={{ display: "flex" }}>
            <div
              className={styles.button}
              style={{ backgroundColor: "orange" }}
              onClick={deleteClicked}
            >
              Yes, delete!
            </div>
            <div
              className={styles.button}
              style={{ backgroundColor: "grey", borderBottomColor: "darkgrey" }}
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
