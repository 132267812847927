import React, { useEffect, useState } from "react";

import {
  CheckCircle,
  Close,
  Delete,
  Description,
  DoNotDisturbOn,
  EmojiObjects,
  HourglassFull,
  Leaderboard,
  Password,
  Poll,
  SportsEsports,
} from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  Modal,
  IconButton,
  CircularProgress,
} from "@mui/material";
import styles from "./GameRoomPage.module.css";
import { db, realtimeDb } from "../../../utils/firebase";
import { useParams } from "react-router";

export default function GameRoomPage() {
  const { gameRoomId } = useParams();

  const [gameAnswers, setGameAnswers] = useState([]);
  const [players, setPlayers] = useState([]);

  const [surveys, setSurveys] = useState({});

  const [isLoadingTeacherSurveyResult, setIsLoadingTeacherSurveyResult] =
    useState(false);
  const [teacherSurveyResultOutOfTen, setTeacherSurveyResultOutOfTen] =
    useState();
  const [teacherSurveyResultDescription, setTeacherSurveyResultDescription] =
    useState();

  const [showTeacherDescriptionPopup, setShowTeacherDescriptionPopup] =
    useState(false);

  const [isLoadingPlayers, setIsLoadingPlayers] = useState(true);

  const [selectedUserSurvey, setSelectedUserSurvey] = useState();

  const [gameRoom, setGameRoom] = useState();

  console.log("gameroom in gameroompage", gameRoom);

  const gameState = gameRoom && gameRoom.state;

  const getGameStateIcon = (gameState) => {
    if (gameState == "lobby") {
      return <HourglassFull style={{ marginRight: "10px" }} />;
    } else if (gameState == "question") {
      return <SportsEsports style={{ marginRight: "10px" }} />;
    } else if (gameState == "finished") {
      return <CheckCircle style={{ marginRight: "10px" }} />;
    } else if (gameState == "leaderboard") {
      return <Leaderboard style={{ marginRight: "10px" }} />;
    } else if (gameState == "solution") {
      return <EmojiObjects style={{ marginRight: "10px" }} />;
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getPlayerRankings = () => {
    let playerRankings = [];

    let playerScoresDict = {};

    console.log("gameAnswers", gameAnswers);

    for (const gameAnswer of gameAnswers) {
      if (!playerScoresDict[gameAnswer.userId]) {
        playerScoresDict[gameAnswer.userId] = 0;
      }
      playerScoresDict[gameAnswer.userId] += gameAnswer.score;
    }

    for (const player of players) {
      playerRankings.push({
        player,
        score: playerScoresDict[player.userId]
          ? playerScoresDict[player.userId]
          : 0,
      });
    }

    // order the players by score
    playerRankings.sort((a, b) => b.score - a.score);

    let ranking = 1;
    for (let i = 0; i < playerRankings.length; i++) {
      if (i > 0 && playerRankings[i].score !== playerRankings[i - 1].score) {
        ranking = i + 1;
      }
      playerRankings[i].ranking = ranking;
    }

    return playerRankings;
  };

  console.log("players", getPlayerRankings());

  // we need to get all the players in the gameRoom
  useEffect(() => {
    const main = async () => {
      try {
        console.log("gameRoom id for onSnapshot: " + gameRoomId);
        const unsubscribe = db
          .collection("players")
          .where("gameRoomId", "==", gameRoomId)
          .onSnapshot(
            (snapshot) => {
              console.log("setting players");
              console.log("snapshot: ", snapshot);
              setPlayers(
                snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
              );

              setIsLoadingPlayers(false);

              // if the state is
            },
            (error) => {
              console.log(error);
            }
          );
        return () => unsubscribe();
      } catch (error) {
        console.log(error);
      }
    };
    main();
  }, [gameRoomId]);

  useEffect(() => {
    const main = async () => {
      try {
        console.log("gameRoom id for onSnapshot: " + gameRoomId);
        const unsubscribe = db
          .collection("quizSurveys")
          .where("gameRoomId", "==", gameRoomId)
          .onSnapshot(
            (snapshot) => {
              console.log("snapshot in quizsurveys: ", snapshot);

              let surveysLoc = {};
              for (const doc of snapshot.docs) {
                console.log("not calling here");
                const isTeacher = doc.data().isTeacher;
                if (isTeacher) {
                  setTeacherSurveyResultOutOfTen(doc.data().ratingOutOfTen);
                  setTeacherSurveyResultDescription(
                    doc.data().teacherDescription
                  );
                } else {
                }
                surveysLoc[doc.data().userId] = doc.data();
                console.log("data of survye", doc.data());
              }

              console.log("surveysloc", snapshot.docs);

              setSurveys(surveysLoc);
              setIsLoadingTeacherSurveyResult(false);

              // if the state is
            },
            (error) => {
              console.log(error);
            }
          );
        return () => unsubscribe();
      } catch (error) {
        console.log(error);
      }
    };
    main();
  }, [gameRoomId]);

  useEffect(() => {
    const main = async () => {
      try {
        console.log("gameRoom id for onSnapshot: " + gameRoomId);
        const unsubscribe = db
          .collection("gameRooms")
          .where("gameRoomId", "==", gameRoomId)
          .onSnapshot(
            (snapshot) => {
              for (const doc of snapshot.docs) {
                setGameRoom({ id: doc.id, ...doc.data() });
              }

              // if the state is
            },
            (error) => {
              console.log(error);
            }
          );
        return () => unsubscribe();
      } catch (error) {
        console.log(error);
      }
    };
    main();
  }, [gameRoomId]);

  useEffect(() => {
    const main = async () => {
      try {
        // START of equivalent in firestore
        // const unsubscribe = db
        //   .collection("gameAnswers")
        //   .where("gameRoomId", "==", gameRoomId)
        //   .onSnapshot(
        //     (snapshot) => {
        //       setGameAnswers(
        //         snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        //       );
        //     },
        //     (error) => {
        //       displayErrorAlert();
        //       console.log(error);
        //     }
        //   );
        // END of equivalent in firestore

        const gameAnswersRef = realtimeDb.ref("gameAnswers/" + gameRoomId);
        const query = gameAnswersRef;
        const unsubscribe = query.on(
          "value",
          (snapshot) => {
            const gameAnswers = [];
            snapshot.forEach((childSnapshot) => {
              console.log("childsnapshot.val", childSnapshot.val());
              gameAnswers.push({
                ...childSnapshot.val(),
                id: childSnapshot.key,
              });
            });
            setGameAnswers(gameAnswers);
          },
          (error) => {
            console.log(error);
          }
        );

        // START of equivalent in firestore
        // return () => unsubscribe();
        // END of equivalent in firestore

        return () => unsubscribe.off();
      } catch (error) {
        console.log(error);
      }
    };
    main();
  }, [gameRoomId]);

  // const playerWithScores = getPlayerRankings();

  console.log("players", players);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div
          style={{
            margin: "10px",
            fontSize: "24px",
            backgroundColor: "grey",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 20px",
            borderRadius: "20px",
            fontWeight: "600",
          }}
        >
          {/* {userIdsDict &&
            gameRoom &&
            gameRoom.teacherId &&
            userIdsDict[gameRoom.teacherId] &&
            userIdsDict[gameRoom.teacherId].name} */}
        </div>
        <div
          style={{
            margin: "10px",
            fontSize: "24px",
            backgroundColor: "grey",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 20px",
            borderRadius: "20px",
            fontWeight: "600",
          }}
        >
          {/* {getQuizInfoString()} */}
        </div>

        <div
          style={{
            margin: "10px",
            fontSize: "24px",
            backgroundColor: "orange",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 20px",
            borderRadius: "20px",
            fontWeight: "600",
          }}
        >
          {isLoadingTeacherSurveyResult ? (
            <CircularProgress />
          ) : teacherSurveyResultOutOfTen ? (
            <div>
              {teacherSurveyResultDescription && (
                <IconButton
                  onClick={() => setShowTeacherDescriptionPopup(true)}
                >
                  <Description />
                </IconButton>
              )}

              {teacherSurveyResultOutOfTen + "/10"}
            </div>
          ) : (
            "No survey"
          )}
        </div>

        <div
          style={{
            margin: "10px",
            fontSize: "24px",
            backgroundColor: "orange",
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px 20px",
            borderRadius: "20px",
            fontWeight: "600",
          }}
        >
          {getGameStateIcon(gameState)}{" "}
          {gameState && capitalizeFirstLetter(gameState)}
        </div>

        {gameRoom && gameState != "lobby" && (
          <div
            style={{
              margin: "10px",
              fontSize: "24px",
              backgroundColor: "orange",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 20px",
              borderRadius: "20px",
              fontWeight: "600",
            }}
          >
            {"Question " +
              (gameRoom.questionIndex + 1) +
              "/" +
              gameRoom.totalQuestions}
          </div>
        )}
      </div>
      <div className={styles.leaderboard}>
        {isLoadingPlayers ? (
          <CircularProgress />
        ) : (
          getPlayerRankings().map((player) => (
            <div className={styles.leaderboardRow}>
              {surveys[player.player.id] && (
                <div style={{ display: "inline-flex", fontSize: "10px" }}>
                  <IconButton
                    onClick={() => {
                      setSelectedUserSurvey(surveys[player.player.id]);
                    }}
                  >
                    <Poll
                      style={{
                        color: surveys[player.player.id].hasGlitches
                          ? "red"
                          : "grey",
                      }}
                    />
                  </IconButton>
                </div>
              )}

              <div className={styles.name}>{player.player.nickname}</div>
              <div className={styles.score}>{player.score}</div>
            </div>
          ))
        )}
      </div>
      <Modal
        open={showTeacherDescriptionPopup}
        onClose={() => setShowTeacherDescriptionPopup(false)}
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "50px",
            width: "300px",
            borderRadius: "30px",
          }}
        >
          <div>
            <b>Describe your experience..</b> {teacherSurveyResultDescription}
          </div>
        </div>
      </Modal>
      <Modal
        open={selectedUserSurvey}
        onClose={() => setSelectedUserSurvey()}
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            padding: "50px",
            width: "300px",
            borderRadius: "30px",
          }}
        >
          <div>
            <b>Did you experience glitches?</b>{" "}
            {selectedUserSurvey && selectedUserSurvey.hasGlitches
              ? "yes"
              : "no"}
          </div>
          <div>
            <b>Explain the issues?</b>{" "}
            {selectedUserSurvey && selectedUserSurvey.description}
          </div>
        </div>
      </Modal>
    </div>
  );
}
