import React from "react";
import { Link } from "react-router-dom";

type ChooseUtilityPageProps = {};

const ChooseUtilityPage: React.FC<ChooseUtilityPageProps> = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link to="/database/realtime/collabUpdates">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "dodgerblue",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid blue",
            display: "inline-block",
            cursor: "pointer",
            margin: "10px",
          }}
        >
          Collab updates
        </div>
      </Link>
    </div>
  );
};

export default ChooseUtilityPage;
