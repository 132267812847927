import { SettingsSystemDaydreamOutlined } from "@mui/icons-material";
import * as React from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase, { auth, db } from "../utils/firebase";

function LoginPage(props) {
  const uiConfig = {
    signInFlow: "popup",
    signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
  };

  return (
    <div style={{ marginTop: "250px" }}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </div>
  );
}
export default LoginPage;
