import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FirestoreCollectionName } from "../../../../types/FirestoreCollectionName";
import { downloadCollection } from "../../utils/downloadCollection";

const firestoreCollectionNames: FirestoreCollectionName[] = [
  "assignedGameStates",
  "chapterLockData",
  "classroomContent",
  "classroomInteractiveQuizAnswers",
  "classroomInteractiveQuizzes",
  "classrooms",
  "codingExerciseAttempts",
  "codingRoomsCourseUploadProgress",
  "consumerEmails",
  "coupons",
  "gameAnswers",
  "gameRooms",
  "globalNumbers",
  "inventory",
  "latencyMetricsMaster",
  "manualSubscriptions",
  "mathClassSurveys",
  "notifications",
  "pageScrollActivity",
  "players",
  "products",
  "progress",
  "projects",
  "questions",
  "quizQuestionIssues",
  "quizSurveys",
  "quizzes",
  "savedCode",
  "schools",
  "studentSubmissions",
  "surveys",
  "teamProjects",
  "teamProjectsSolutions",
  "teams",
  "teamsBlockFiles",
  "teamsCourseItems",
  "teamsCreationProgress",
  "teamsIssues",
  "teamsIssuesEmail",
  "teamsProgress",
  "teamsProjectUploadProgress",
  "teamsProjectsShareLinks",
  "teamsQuestionSubmissions",
  "teamsTestResults",
  "teamsUserProjects",
  "textbookSummaries",
  "tutorialComponents",
  "users",
  "utils",
];

type BackupDataPageProps = {};

const BackupDataPage: React.FC<BackupDataPageProps> = () => {
  const [collectionName, setCollectionName] =
    useState<FirestoreCollectionName | null>(null);

  const [isDownloadingCollection, setIsDownloadingCollection] =
    useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <FormControl style={{ width: "200px" }}>
        <InputLabel id="firestore-collection-select-label">
          Firestore Collection
        </InputLabel>
        <Select
          labelId="firestore-collection-select-label"
          value={collectionName}
          // @ts-ignore:next-line
          onChange={(
            event: React.ChangeEvent<{ value: FirestoreCollectionName }>
          ) => {
            setCollectionName(event.target.value as FirestoreCollectionName);
          }}
          label="Firestore Collection"
        >
          {firestoreCollectionNames.map((collectionName) => (
            <MenuItem key={collectionName} value={collectionName}>
              {collectionName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="/database/firestore">
          <Button
            color="warning"
            style={{ margin: "10px" }}
            variant="contained"
          >
            Cancel
          </Button>
        </Link>
        <Button
          disabled={!collectionName || isDownloadingCollection}
          style={{ margin: "10px" }}
          variant="contained"
          onClick={async () => {
            setIsDownloadingCollection(true);

            try {
              // download collection
              await downloadCollection(collectionName!);
            } catch (error) {
              console.error(error);
            }

            setIsDownloadingCollection(false);
          }}
        >
          Download Data
        </Button>
      </div>
    </div>
  );
};

export default BackupDataPage;
