import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { fetchTeams } from "../../utils/fetchTeams";

type CollabUpdatesPageProps = {};

const CollabUpdatesPage: React.FC<CollabUpdatesPageProps> = () => {
  const [isRetrievingTeams, setIsRetrievingTeams] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        disabled={isRetrievingTeams}
        style={{ margin: "10px" }}
        variant="contained"
        onClick={async () => {
          setIsRetrievingTeams(true);

          try {
            // download collection
            await fetchTeams();
          } catch (error) {
            console.error(error);
          }

          setIsRetrievingTeams(false);
        }}
      >
        Download Data
      </Button>
    </div>
  );
};

export default CollabUpdatesPage;
