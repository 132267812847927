import React from "react";
import { Switch, Route } from "react-router-dom";
import ChooseUtilityPage from "./pages/ChooseUtilityPage/ChooseUtilityPage";
import CollabUpdatesPage from "./pages/CollabUpdatesPage/CollabUpdatesPage";

type RealtimePageProps = {};

const RealtimePage: React.FC<RealtimePageProps> = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: "30px", marginTop: "10px" }}>
        Realtime Admin Dashboard
      </div>

      <Switch>
        <Route exact path="/database/realtime/collabUpdates">
          <CollabUpdatesPage />
        </Route>
        <Route path="/">
          <ChooseUtilityPage />
        </Route>
      </Switch>
    </div>
  );
};

export default RealtimePage;
