import * as React from "react";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

const columns = [
  { id: "firstName", label: "First name", minWidth: 170 },
  { id: "lastName", label: "Last name", minWidth: 170 },
  {
    id: "email",
    label: "Email",
    minWidth: 170,
  },
  {
    id: "emailBounces",
    label: "Email bounces",
    minWidth: 170,
  },
  //added
  {
    id: "check",
    label: "Followed Up",
    minWidth: 170,
  },
  {
    id: "type",
    label: "Type",
    minWidth: 170,
  },
  {
    id: "classroomId",
    label: "Classroom\u00a0ID",
    minWidth: 170,
  },
  {
    id: "totalStudents",
    label: "Total\u00a0Students",
    minWidth: 170,
  },
  {
    id: "tutorial",
    label: "Tutorial",
    minWidth: 170,
  },
  {
    id: "startDate",
    label: "Sign\u00a0Up\u00a0Date",
    minWidth: 170,
  },
  {
    id: "sdbProgress",
    label: "Smart Doorbell",
    minWidth: 170,
  },
  {
    id: "pplProgress",
    label: "Ping Pong Launcher",
    minWidth: 170,
  },
  {
    id: "deleteButton",
    label: "Delete User",
    minWidth: 170,
  },
];

function WeeklyMoversTableHeader() {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.id}
            align={column.align}
            style={{ minWidth: column.minWidth }}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default WeeklyMoversTableHeader;
