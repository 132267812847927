import React, {
  useCallback,
  useEffect,
  useState,
  type ChangeEvent,
  type ReactNode,
} from "react";

import { ContentCopy } from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  type SelectChangeEvent,
  Modal,
  Button,
} from "@mui/material";

import styles from "./KnownIssuesPage.module.css";

import ControlledCheckboxRealEmail from "../ControlledCheckboxRealEmail";
import { db } from "../../utils/firebase";
import firebase from "../../utils/firebase";
import type { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore";
import type { TKnownIssue } from "./interface";
import KnownIssueModal from "../KnownIssueModal";

export default function KnownIssuesPage({}) {
  const [showCopySuccess, setShowCopySuccess] = useState(false);
  const [, setRefetchData] = useState(null);

  const [knownIssues, setKnownIssues] = useState<TKnownIssue[]>([]);
  const [openNewRecordModal, setOpenNewRecordModal] = useState(false);
  const [selectedKnownIssue, setSelectedKnownIssue] =
    useState<TKnownIssue | null>(null);

  const [lastVisible, setLastVisible] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // New state to track if more data is available

  const headers = [
    "ID",
    "Edit",
    "Title",
    "Short Description",
    "Scope",
    "Type",
    "Position",
    "Delete",
  ];

  const fetchData = useCallback(
    async (lastVisible: QueryDocumentSnapshot<DocumentData> | undefined) => {
      setLoading(true);

      try {
        let query = db
          .collection("knownIssues")
          .orderBy("position", "asc")
          .limit(50);

        // Only apply pagination if lastVisible is defined
        if (lastVisible) {
          query = query.startAfter(lastVisible);
        }

        const querySnapshot = await query.get();

        if (querySnapshot.empty) {
          console.warn("No documents found in the collection.");
        } else {
          console.log(`${querySnapshot.size} documents fetched`);

          // Set the last visible document for pagination
          const lastVisibleDoc =
            querySnapshot.docs[querySnapshot.docs.length - 1];
          setLastVisible(lastVisibleDoc as QueryDocumentSnapshot<DocumentData>);

          const items = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // For pagination, append the new results
          setKnownIssues((prev) => [...prev, ...(items as TKnownIssue[])]);

          // If fewer than 50 documents are returned, no more data to fetch
          if (querySnapshot.size < 50) {
            setHasMore(false);
          } else {
            setHasMore(true);
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [lastVisible, hasMore]
  );

  const handleDeleteIssue = (issue: TKnownIssue) => {
    const issueId = issue?.id;

    if (issueId) {
      console.log("Deleting issue with ID:", issueId);

      firebase
        .firestore()
        .collection("knownIssues")
        .doc(issueId)
        .delete()
        .then(() => {
          console.log("Issue successfully deleted!");

          setKnownIssues((prevIssues) =>
            prevIssues.filter((i) => i.id !== issueId)
          );
        })
        .catch((error) => {
          console.error("Error deleting issue: ", error);
          alert("Failed to delete the issue. Please try again.");
        });
    } else {
      console.warn("Invalid issue, no ID provided.");
    }
  };

  useEffect(() => {
    const main = async () => {
      setKnownIssues([]);
      fetchData(lastVisible);
    };
    main();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRefetchData(() => fetchData);
  }, [fetchData, setRefetchData]);

  const getUserSubscriptionsDataArray = (items: TKnownIssue[]) => {
    return items.map((row, index) => [
      { text: index + 1 },
      { selectedIssue: row, type: "editButton" },
      {
        isCopyable: true,
        text: row.title,
        onChange: (
          e:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string>
        ) => {},
      },
      {
        text: row.shortDesc || "-",
      },
      {
        text: (row.scope || []).join(", ") || "-",
      },
      {
        text: row.type ? row.type : "warning",
        selector: {
          options: [
            { value: "error", text: "Error" },
            { value: "info", text: "Info" },
            { value: "warning", text: "Warning" },
          ],
          onChange: async (
            evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const newValue = evt.target.value;

            let finalPermValue: string | null = null;

            if (
              newValue.toLowerCase() === "error" ||
              newValue.toLowerCase() === "info" ||
              newValue.toLowerCase() === "warning"
            ) {
              finalPermValue = newValue;
            }

            await db.collection("knownIssues").doc(row.id).update({
              type: finalPermValue,
            });

            setKnownIssues((prevState) => {
              return prevState.map((issue) => {
                if (issue.id === row.id) {
                  return {
                    ...issue,
                    type: finalPermValue,
                  };
                } else {
                  return issue;
                }
              });
            });
          },
        },
      },
      {
        text: row.position || "-",
      },
      { selectedIssue: row, type: "deleteButton" },
    ]);
  };

  const copyToClipboard = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      setShowCopySuccess(true);
    } catch (err) {
      setShowCopySuccess(false);
    }
  };

  return (
    <div
      style={{
        padding: "0px 40px",
        marginTop: "42px",
        display: "flex",
        flex: 1,
        width: "90%",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      <button
        style={{
          backgroundColor: "green",
          borderRadius: "10px",
          padding: "10px 20px",
          color: "white",
          alignSelf: "flex-end",
          cursor: "pointer",
          marginBottom: 12,
        }}
        onClick={() => setOpenNewRecordModal(true)}
      >
        Add New Record
      </button>

      <Paper sx={{ width: "100%", position: "relative", marginBottom: "42px" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={styles.tableRow}>
                {headers.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getUserSubscriptionsDataArray(knownIssues).map(
                (issue, parentIndex) => {
                  return (
                    <TableRow key={parentIndex}>
                      {issue.map(
                        (
                          {
                            selectedIssue,
                            text,
                            onChange,
                            isCopyable,
                            selector,
                            type,
                          },
                          index
                        ) => (
                          <TableCell>
                            {type === "editButton" ? (
                              <Button
                                color="info"
                                variant="outlined"
                                onClick={() => {
                                  setOpenNewRecordModal(true);
                                  setSelectedKnownIssue(selectedIssue);
                                }}
                              >
                                Edit Issue
                              </Button>
                            ) : type === "deleteButton" ? (
                              <Button
                                color="error"
                                variant="outlined"
                                onClick={() => handleDeleteIssue(selectedIssue)}
                              >
                                DELETE
                              </Button>
                            ) : headers[index + 2] === "Email bounced" ? (
                              <ControlledCheckboxRealEmail
                                value={text}
                                onChange={onChange}
                              />
                            ) : (
                              <>
                                {isCopyable && (
                                  <IconButton
                                    sx={{ marginRight: "10px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      copyToClipboard(text);
                                    }}
                                  >
                                    <ContentCopy sx={{ fontSize: "20px" }} />
                                  </IconButton>
                                )}

                                {selector ? (
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Type
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={text}
                                      label="Age"
                                      onChange={
                                        selector.onChange as unknown as
                                          | ((
                                              event: SelectChangeEvent<string>,
                                              child: ReactNode
                                            ) => void)
                                          | undefined
                                      }
                                    >
                                      {selector.options.map(
                                        ({ text, value }) => (
                                          <MenuItem value={value}>
                                            {text}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <div
                                    style={
                                      false
                                        ? {
                                            color: "blue",
                                            textDecoration: "unerline",
                                          }
                                        : {}
                                    }
                                  >
                                    {text}
                                  </div>
                                )}
                              </>
                            )}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
            {hasMore && (
              <button onClick={fetchData} disabled={loading}>
                {loading ? "Loading..." : "Load More"}
              </button>
            )}
          </Table>
        </TableContainer>
        <Snackbar
          open={showCopySuccess}
          onClose={() => setShowCopySuccess(false)}
          autoHideDuration={2000}
          message="Copied email to clipboard"
        />
        <KnownIssueModal
          open={openNewRecordModal}
          setOpen={(newValue: boolean) => setOpenNewRecordModal(newValue)}
          issueId={selectedKnownIssue?.id}
          issueType={selectedKnownIssue?.type}
          issueShortDesc={selectedKnownIssue?.shortDesc}
          issuePosition={selectedKnownIssue?.position}
          issueScope={selectedKnownIssue?.scope}
          issueContent={selectedKnownIssue?.content}
          issueTitle={selectedKnownIssue?.title}
          onFinish={() => {
            setSelectedKnownIssue(undefined);
            setKnownIssues([]);
            fetchData(undefined);
          }}
        />
      </Paper>
    </div>
  );
}
