import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { db } from "../../../utils/firebase";

function AddBusinessButtonAlert({ users, userId, onFinish }) {
  const [open, setOpen] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [businessName, setBusinessName] = useState("");
  const [state, setState] = useState("");
  const [district, setDistrict] = useState("");

  const handleCreateNewBusinessName = async () => {
    setShowError(false);
    setIsSubmitting(true);

    // Check if the required fields are filled
    if (!state || !district || !businessName) {
      setIsSubmitting(false);
      return setShowError(true);
    }

    try {
      // Reference to the collection with the document ID being the business name
      const docRef = db
        .collection("domainToSchoolDistrictAndStateMapping")
        .doc(businessName);

      // Create or update the document with state and district fields
      await docRef.set({
        state: state,
        district: district,
      });
    } catch (error) {
      console.error("Error creating/updating document:", error);
    }

    onFinish?.();
    setIsSubmitting(false);
    setOpen(false);
  };

  const handleOpenModal = () => {
    const user = users.find((u) => u.id === userId);
    const emailDomain = user.email.split("@")[1];

    setBusinessName(emailDomain);
    setOpen(true);
  };

  return (
    <>
      <Button
        onClick={handleOpenModal}
        size="small"
        color="info"
        variant="outlined"
      >
        Create Business
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">Add Business</DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={2} id="edit-dialog-description">
            Create a new business
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="businessName"
            label="Business Name"
            type="text"
            disabled
            fullWidth
            value={businessName}
            required
            error={showError && businessName.length === 0}
            onChange={(e) => setBusinessName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="state"
            label="State"
            type="text"
            fullWidth
            value={state}
            required
            error={showError && state.length === 0}
            onChange={(e) => setState(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="district"
            label="District"
            type="text"
            fullWidth
            value={district}
            required
            error={showError && district.length === 0}
            onChange={(e) => setDistrict(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            startIcon={
              isSubmitting ? <CircularProgress size={12} /> : undefined
            }
            onClick={handleCreateNewBusinessName}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default AddBusinessButtonAlert;
