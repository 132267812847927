import React, { useState } from "react";

import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-plain_text";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/ext-language_tools";

import styles from "./CodeEditor.module.css";

import { Chip, Fab } from "@mui/material";
import {
  Co2Sharp,
  Memory,
  PlayArrow,
  PlayCircle,
  Refresh,
  FileUpload,
} from "@mui/icons-material";
import TerminalIcon from "@mui/icons-material/Terminal";

export default function CodeEditor({
  id,
  code,
  setCode,
  onChange,
  defaultCode,
  runnable,
  readOnly,
  totalEditorLines,
  totalOutputLines,
  displayOutputShell,
  marginTop,
  marginBottom,
  interpreter,
  interpreterContext,
  walkThroughTutorial,
  labels,
  showFlashButton,
}) {
  const [editorRef, setEditorRef] = useState(React.createRef());

  const resetCode = () => {
    editorRef.current.editor.setValue(defaultCode ? defaultCode : "");
  };

  return (
    <div className={`${styles.ide}`}>
      {runnable ? (
        <>
          <div className={styles.tab}>script.py</div>
        </>
      ) : null}

      <AceEditor
        ref={editorRef}
        style={{ width: "100%", borderTopRightRadius: 5 }}
        mode="python"
        theme="dracula"
        onChange={onChange}
        fontSize={14}
        readOnly={readOnly}
        value={code}
        maxLines={totalEditorLines}
        minLines={totalEditorLines}
        showPrintMargin={false}
        scrollMargin={[marginTop, marginBottom, 0, 0]}
        setOptions={{
          autoScrollEditorIntoView: true,
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          highlightActiveLine: !readOnly,
          highlightGutterLine: !readOnly,
          cursorStyle: "smooth",
        }}
      />
      {displayOutputShell ? (
        <>
          <div className={styles.outputLabel}>
            <TerminalIcon
              style={{
                marginLeft: 8,
                fontSize: "2rem",
                display: "inline-block",
                paddingBottom: 0,
                verticalAlign: "middle",
                opacity: 0.6,
              }}
            />{" "}
            <div
              style={{
                marginLeft: 6,
                display: "inline-block",
                verticalAlign: "middle",
              }}
            >
              Output shell
            </div>
          </div>
          <AceEditor
            style={{ width: "100%", paddingLeft: "20px" }}
            className={styles.output}
            mode="plain_text"
            onChange={onChange}
            fontSize={14}
            readOnly={true}
            // cursorStart={0}
            value={
              interpreterContext.currentIDE == id
                ? interpreterContext.outputResult
                : ""
            }
            showGutter={false}
            showPrintMargin={false}
            scrollMargin={[10, 0, 0, 0]}
            maxLines={totalOutputLines}
            minLines={totalOutputLines}
            onLoad={function (editor) {
              editor.renderer.setPadding(10);
              editor.renderer.$cursorLayer.element.style.display = "none";
            }}
            setOptions={{
              autoScrollEditorIntoView: true,
              highlightActiveLine: false,
              highlightGutterLine: false,
              cursorStyle: "undefined",
            }}
          />
        </>
      ) : null}
    </div>
  );
}
