import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

type CustomFunctionsHomePageProps = {};

const CustomFunctionsHomePage: React.FC<
  CustomFunctionsHomePageProps
> = ({}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        onClick={() => {
          setOpenModal(true);
          setIsLoading(true);
        }}
        variant="contained"
        style={{ marginTop: "20px" }}
      >
        Check for teamProjects issues
      </Button>
      <Button variant="contained" style={{ marginTop: "20px" }}>
        Sync teamProjects with teamsUserProjects
      </Button>

      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "20px",
          }}
        >
          {isLoading ? (
            <div style={{ padding: "20px", fontSize: "24px" }}>Loading...</div>
          ) : (
            <div style={{ marginTop: "20px" }}>test</div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default CustomFunctionsHomePage;
