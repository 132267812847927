import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Editor from "react-simple-wysiwyg";
import DialogTitle from "@mui/material/DialogTitle";

import firebase from "../utils/firebase";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";

function KnownIssueModal(props) {
  const [isUpdating, setIsUpdating] = useState(false);
  const [newIssueTitle, setNewIssueTitle] = useState("");
  const [newIssueShortDesc, setNewIssueShortDesc] = useState("");
  const [newIssuePosition, setNewIssuePosition] = useState(0);
  const [newIssueType, setNewIssueType] = useState("warning");
  const [newIssueScope, setNewIssueScope] = useState("teacher");
  const [newIssueContent, setNewIssueContent] = useState("");

  const handleClose = () => {
    props.setOpen(false);
  };

  React.useEffect(() => {
    if (props.issueType) {
      setNewIssueType(props.issueType);
    }
    if (props.issueShortDesc) {
      setNewIssueShortDesc(props.issueShortDesc);
    }
    if (props.issuePosition) {
      setNewIssuePosition(props.issuePosition);
    }
    if (props.issueScope) {
      setNewIssueScope([...props.issueScope]);
    }
    if (props.issueContent) {
      setNewIssueContent(props.issueContent);
    }
    if (props.issueTitle) {
      setNewIssueTitle(props.issueTitle);
    }
  }, [
    props.issueContent,
    props.issueId,
    props.issuePosition,
    props.issueScope,
    props.issueShortDesc,
    props.issueTitle,
    props.issueType,
  ]);

  const handleSave = () => {
    setIsUpdating(true);
    const newIssue = {
      title: newIssueTitle,
      shortDesc: newIssueShortDesc,
      position: newIssuePosition,
      type: newIssueType,
      scope: [
        ...(Array.isArray(newIssueScope) ? newIssueScope : [newIssueScope]),
      ],
      content: newIssueContent,
    };

    const issueId = props.issueId;
    const collectionRef = firebase.firestore().collection("knownIssues");

    if (issueId) {
      // Update existing document
      collectionRef
        .doc(issueId)
        .update(newIssue)
        .then(() => {
          setNewIssueContent("");
          setNewIssuePosition(0);
          setNewIssueShortDesc("");
          setNewIssueTitle("");
          setNewIssueType("warning");
          setNewIssueScope("teacher");

          props.onFinish?.();
          console.log("Successfully updated!");
        })
        .catch((error) => {
          console.error(
            "There was an error updating the document, details: " + error
          );
        })
        .finally(() => {
          setIsUpdating(false);
          props.setOpen(false);
        });
    } else {
      // Create new document
      collectionRef
        .add(newIssue)
        .then((docRef) => {
          setNewIssueContent("");
          setNewIssuePosition(0);
          setNewIssueShortDesc("");
          setNewIssueTitle("");
          setNewIssueType("warning");
          setNewIssueScope("teacher");

          props.onFinish?.();
          console.log("Document successfully created with ID: ", docRef.id);
        })
        .catch((error) => {
          console.error(
            "There was an error creating the document, details: " + error
          );
        })
        .finally(() => {
          setIsUpdating(false);
          props.setOpen(false);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">
          {props?.isEdit ? "Edit Issue" : "Create New Issue"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            value={newIssueTitle}
            onChange={(e) => setNewIssueTitle(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="shortDesc"
            label="Short Description"
            type="text"
            fullWidth
            value={newIssueShortDesc}
            onChange={(e) => setNewIssueShortDesc(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="position"
            label="Position"
            type="text"
            fullWidth
            value={newIssuePosition}
            onChange={(e) => {
              const newPosition = Number(e.target.value);

              if (!Number.isNaN(newPosition)) {
                setNewIssuePosition(newPosition);
              }
            }}
          />
          <FormControl fullWidth style={{ marginTop: 8 }}>
            <InputLabel id="scope-label">Scope</InputLabel>
            <Select
              labelId="scope-label"
              id="Scope"
              style={{ marginBottom: "12px" }}
              value={newIssueScope}
              label="Scope"
              onChange={(evt) => {
                const newValue = evt.target.value;

                let finalPermValue = null;

                if (
                  newValue.toLowerCase() === "teacher" ||
                  newValue.toLowerCase() === "student" ||
                  newValue.toLowerCase() === "all"
                ) {
                  finalPermValue = newValue;
                }

                setNewIssueScope(finalPermValue);
              }}
            >
              {[
                { value: "teacher", text: "Teacher" },
                { value: "student", text: "Student" },
                { value: "all", text: "Both" },
              ].map(({ text, value }) => (
                <MenuItem value={value}>{text}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginTop: 8 }}>
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              id="Type"
              style={{ marginBottom: "4px" }}
              value={newIssueType}
              label="Type"
              onChange={(evt) => {
                const newValue = evt.target.value;

                let finalPermValue = null;

                if (
                  newValue.toLowerCase() === "error" ||
                  newValue.toLowerCase() === "info" ||
                  newValue.toLowerCase() === "warning"
                ) {
                  finalPermValue = newValue;
                }

                setNewIssueType(finalPermValue);
              }}
            >
              {[
                { value: "error", text: "Error" },
                { value: "info", text: "Info" },
                { value: "warning", text: "Warning" },
              ].map(({ text, value }) => (
                <MenuItem value={value}>{text}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <div
            style={{
              border: "1px solid #909090",
              borderRadius: 5,
              marginTop: 8,
              overflow: "hidden",
            }}
          >
            <Editor
              value={newIssueContent}
              onChange={(e) => setNewIssueContent(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            startIcon={isUpdating ? <CircularProgress size={14} /> : null}
            onClick={handleSave}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default KnownIssueModal;
