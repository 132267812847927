import React, { useState } from "react";

import { Route, Switch, useRouteMatch } from "react-router-dom";
import Dashboard from "../../Components/UserSubscriptionsPage/UserSubscriptionsTable";

import CreateSubscriptionModal from "./CreateSubscriptionModal/CreateSubscriptionModal";

export default function UserSubscriptions() {
  const [refetchData, setRefetchData] = useState<() => void>(() => {});
  const [openCreateSubscriptionModal, setOpenCreateSubscriptionModal] =
    useState(false);

  let match = useRouteMatch();

  return (
    <div
      style={{
        padding: "0px 40px",
        display: "flex",
        flex: 1,
        width: "90%",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: 32,
        }}
      >
        <button
          style={{
            backgroundColor: "green",
            borderRadius: "10px",
            padding: "10px 20px",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => setOpenCreateSubscriptionModal(true)}
        >
          Create Subscription
        </button>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginTop: "42px",
          width: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Switch>
          <Route path={`${match.path}`}>
            <Dashboard setRefetchData={setRefetchData} />
          </Route>
        </Switch>
      </div>
      <CreateSubscriptionModal
        onFinish={refetchData}
        open={openCreateSubscriptionModal}
        onClose={() => setOpenCreateSubscriptionModal(false)}
      />
    </div>
  );
}
