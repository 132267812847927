import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import firebase, { db, auth } from "../utils/firebase";

function ControlledCheckbox(props) {
  const [checked, setChecked] = React.useState(props.user.followedUp);

  const handleChange = (event) => {
    setChecked(event.target.checked);

    // db.collection('users').doc(user.id).field('followedUp').set(true);
    db.collection("users").doc(props.user.id).update({
      followedUp: event.target.checked,
    });
    // db.collection("cities").doc("DC").update({
    //     capital: true
    // })
  };

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}

export default ControlledCheckbox;
