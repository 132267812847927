import React from "react";
import GameRoomsTable from "../../general/GameRoomsTable/GameRoomsTable";
import UsersQuizTable from "../../general/UsersQuizTable/UsersQuizTable";

export default function Dashboard() {
  return (
    <div
      style={{
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
      }}
    >
      <div
        style={{
          fontSize: "30px",
          textAlign: "left",
          fontWeight: "700",
          backgroundColor: "orange",
          padding: "10px 20px",
          borderRadius: "10px",
          display: "inline-block",
          color: "white",
          margin: "5px",
        }}
      >
        Users
      </div>
      <UsersQuizTable />
      <div style={{ paddingTop: "40px" }}>
        <div
          style={{
            fontSize: "30px",
            textAlign: "left",
            fontWeight: "700",
            backgroundColor: "orange",
            padding: "10px 20px",
            borderRadius: "10px",
            display: "inline-block",
            color: "white",
            margin: "5px",
          }}
        >
          Recent Games
        </div>
        <GameRoomsTable />
      </div>{" "}
    </div>
  );
}
