import React, { useEffect, useState } from "react";

import { ContentCopy } from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
  Snackbar,
} from "@mui/material";

import styles from "./ConsumersUsagePage.module.css";

import { db } from "../../utils/firebase";
import firebase from "../../utils/firebase";
import type { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore";
import type { TConsumerUsage, TUsageFilters } from "./interface";

export default function ConsumersUsagePage({}) {
  const [activeFilter, setActiveFilter] = useState<null | TUsageFilters>(null);
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const [consumerUsage, setConsumerUsage] = useState<TConsumerUsage[]>([]);

  const [lastVisible, setLastVisible] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // New state to track if more data is available

  const headers = ["ID", "Name", "Email", "Usage Status"];

  const getConsumerTeams = async (consumerId: string) => {
    const teamsDocs = await db
      .collection("teams")
      .orderBy(`teamMembers.${consumerId}`, "desc")
      .get();

    const teams = teamsDocs.docs.map((doc) => doc.id);
    return teams;
  };

  const getConsumerExerciseAttempts = async (consumerId: string) => {
    const teamsProgressDocs = await db
      .collection("teamsProgress")
      .where("userId", "==", consumerId)
      .get();

    let totalExercisesAttempted = 0;
    if (teamsProgressDocs.docs.length > 0) {
      teamsProgressDocs.docs.forEach((doc) => {
        if (
          doc.exists &&
          doc.data().hasOwnProperty("data") &&
          typeof doc.data().data === "object"
        ) {
          totalExercisesAttempted +=
            Object.keys(doc.data().data)?.length || totalExercisesAttempted;
        }
      });
    }

    return totalExercisesAttempted;
  };

  const getConsumerTestResults = async (consumerId: string) => {
    const teamsTestResultsDocs = await db
      .collection("teamsTestResults")
      .where("userId", "==", consumerId)
      .get();

    let totalTestResults = { total: 0, passed: 0, failed: 0 };
    if (teamsTestResultsDocs.docs.length > 0) {
      teamsTestResultsDocs.docs.forEach((doc) => {
        if (
          doc.exists &&
          doc.data().hasOwnProperty("result") &&
          typeof doc.data().result === "object"
        ) {
          totalTestResults.total +=
            Object.keys(doc.data().result)?.length || totalTestResults.total;
          Object.keys(doc.data().result)?.forEach((key) => {
            const testResult = doc.data().result[key];
            if (testResult.passed) {
              totalTestResults.passed++;
            } else {
              totalTestResults.failed++;
            }
          });
        }
      });
    }

    return totalTestResults;
  };

  const getConsumerProjects = async (consumerId: string) => {
    const teamsUserProjectsDocs = await db
      .collection("teamsUserProjects")
      .where("userId", "==", consumerId)
      .get();

    let totalProjectsAttempted = 0;
    if (teamsUserProjectsDocs.docs.length > 0) {
      teamsUserProjectsDocs.docs.forEach((doc) => {
        if (doc.exists) {
          totalProjectsAttempted++;
        }
      });
    }
    return totalProjectsAttempted;
  };

  const getConsumerSubmissions = async (consumerId: string) => {
    let totalSubmissions = 0;
    const studentSubmissionsDocs = await db
      .collection("studentSubmissions")
      .where("userId", "==", consumerId)
      .get();

    if (studentSubmissionsDocs.docs.length > 0) {
      studentSubmissionsDocs.docs.forEach((doc) => {
        if (
          doc.exists &&
          doc.data().hasOwnProperty("submissions") &&
          typeof doc.data().submissions === "object"
        ) {
          totalSubmissions +=
            Object.keys(doc.data().submissions)?.length || totalSubmissions;
        }
      });
    }

    return totalSubmissions;
  };

  const saveOrUpdateUsageData = async (data: any) => {
    const consumerId = data.userId;
    const docRef = db.collection("consumerUsage").doc(consumerId);

    await docRef.set(
      { ...data, updatedAt: firebase.firestore.FieldValue.serverTimestamp() },
      { merge: true }
    );
  };

  const calculateUsageDifference = (baseline: any, current: any): number => {
    if (!baseline || !Object.keys(baseline)?.length) return 0;

    // Helper function to calculate the percentage change for a single field
    const calculatePercentageChange = (
      currentValue: number,
      baselineValue: number
    ): number => {
      if (baselineValue === 0)
        return currentValue > 0 ? 100 : currentValue < 0 ? -100 : 0; // Handle baseline zero cases
      return ((currentValue - baselineValue) / baselineValue) * 100;
    };

    // Teams
    const teamsChange =
      calculatePercentageChange(
        current.teams.length,
        baseline?.teams?.length || 0
      ) * 0.2; // Adjusting weight to 0.2 for 20%

    // Total Exercises Attempted
    const exercisesChange =
      calculatePercentageChange(
        current.totalExercisesAttempted,
        baseline.totalExercisesAttempted || 0
      ) * 0.2;

    // Total Projects Created
    const projectsChange =
      calculatePercentageChange(
        current.totalProjectsCreated,
        baseline.totalProjectsCreated || 0
      ) * 0.2;

    // Total Submissions
    const submissionsChange =
      calculatePercentageChange(
        current.totalSubmissions,
        baseline.totalSubmissions || 0
      ) * 0.2;

    // Total Test Results (combined effect of 20%)
    const testTotalChange = calculatePercentageChange(
      current.totalTestResults.total,
      baseline.totalTestResults?.total || 0
    );
    const testPassedChange = calculatePercentageChange(
      current.totalTestResults.passed,
      baseline.totalTestResults?.passed || 0
    );
    const testFailedChange = calculatePercentageChange(
      current.totalTestResults.failed,
      baseline.totalTestResults?.failed || 0
    );

    // Combined weighted test results change
    const testResultsChange =
      ((testTotalChange + testPassedChange + testFailedChange) / 3) * 0.2;

    // Final usage difference score, now allowing for values between -100 and +100
    const usageDifference =
      teamsChange +
      exercisesChange +
      projectsChange +
      submissionsChange +
      testResultsChange;

    // Ensure usage difference is within -100 to 100
    return Math.max(-100, Math.min(usageDifference, 100));
  };

  const getConsumerWithUsageStatus = async (consumer: any) => {
    const baselineUsageSnapshot = await db
      .collection("consumerUsage")
      .doc(consumer.id)
      .get();

    const baselineUsageObj = await baselineUsageSnapshot.data();

    const teams = await getConsumerTeams(consumer.id);

    const totalExercisesAttempted = await getConsumerExerciseAttempts(
      consumer.id
    );

    const totalTestResults = await getConsumerTestResults(consumer.id);

    const totalProjectsCreated = await getConsumerProjects(consumer.id);

    const totalSubmissions = await getConsumerSubmissions(consumer.id);

    const currentUsageObj = {
      userId: consumer.id,
      teams: teams || [],
      totalExercisesAttempted: totalExercisesAttempted || 0,
      totalTestResults: totalTestResults || { total: 0, passed: 0, failed: 0 },
      totalProjectsCreated: totalProjectsCreated || 0,
      totalSubmissions: totalSubmissions || 0,
    };

    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(currentDate.getMonth() - 1);
    if (
      baselineUsageObj?.hasOwnProperty("updatedAt") &&
      baselineUsageObj.updatedAt.toDate() >= oneMonthAgo
    ) {
      return new Promise<TConsumerUsage>((resolve) => {
        resolve({ ...consumer, usage: "USES_PRODUCT_REGULARLY" });
      });
    } else {
      await saveOrUpdateUsageData(currentUsageObj);

      const usageDifferenceInPercentage = calculateUsageDifference(
        baselineUsageObj,
        currentUsageObj
      );

      if (usageDifferenceInPercentage >= 35) {
        return new Promise<TConsumerUsage>((resolve) => {
          resolve({ ...consumer, usage: "PRODUCT_USAGE_INCREASED_HEAVILY" });
        });
      } else if (usageDifferenceInPercentage <= -35) {
        return new Promise<TConsumerUsage>((resolve) => {
          resolve({ ...consumer, usage: "PRODUCT_USAGE_DECREASED_HEAVILY" });
        });
      } else {
        return new Promise<TConsumerUsage>((resolve) => {
          resolve({ ...consumer, usage: "USES_PRODUCT_REGULARLY" });
        });
      }
    }
  };

  const fetchData = async (search = "") => {
    setLoading(true);
    let query = db
      .collection("users")
      .where("isTeacher", "==", false)
      .orderBy("createdAt", "desc")
      .limit(50);

    if (lastVisible) {
      query = query.startAfter(lastVisible);
    }

    query
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.docs.length < 50) {
          setHasMore(false); // If fewer documents than the limit are returned, no more data is available
        } else {
          setHasMore(true);
        }
        const lastVisibleDoc =
          querySnapshot.docs[querySnapshot.docs.length - 1];

        setLastVisible(lastVisibleDoc);

        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const usageStatusPromises: Promise<TConsumerUsage>[] = [];
        items.forEach((item) => {
          usageStatusPromises.push(getConsumerWithUsageStatus(item));
        });

        return Promise.all(usageStatusPromises);
      })
      .then((data) => {
        // For pagination, append the new results
        setConsumerUsage((prev) => [...prev, ...data]);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    const main = async () => {
      setConsumerUsage([]);
      fetchData();
    };
    main();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserSubscriptionsDataArray = (items: TConsumerUsage[]) => {
    return items.map((row, index) => [
      { text: index + 1 },
      {
        text: row.name || "-",
      },
      {
        text: row.email || "-",
        isCopyable: true,
      },
      {
        text: row.usage || "-",
      },
    ]);
  };

  const copyToClipboard = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      setShowCopySuccess(true);
    } catch (err) {
      setShowCopySuccess(false);
    }
  };

  return (
    <div
      style={{
        padding: "0px 40px",
        marginTop: "42px",
        display: "flex",
        flex: 1,
        width: "90%",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      <Paper sx={{ width: "100%", position: "relative", marginBottom: "42px" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={styles.tableRow}>
                {headers.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getUserSubscriptionsDataArray(consumerUsage).map(
                (issue, parentIndex) => {
                  return (
                    <TableRow key={parentIndex}>
                      {issue.map(({ text, isCopyable }, index) => (
                        <TableCell>
                          {(() => {
                            switch (text) {
                              case "USES_PRODUCT_REGULARLY":
                                return "Uses product regularly";
                              case "PRODUCT_USAGE_INCREASED_HEAVILY":
                                return "Product usage increased heavily";
                              case "PRODUCT_USAGE_DECREASED_HEAVILY":
                                return "Product usage decreased heavily";
                              default:
                                return text;
                            }
                          })()}
                          {isCopyable && (
                            <IconButton
                              sx={{ marginRight: "10px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                copyToClipboard(text);
                              }}
                            >
                              <ContentCopy sx={{ fontSize: "20px" }} />
                            </IconButton>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
            {hasMore && (
              <button onClick={fetchData} disabled={loading}>
                {loading ? "Loading..." : "Load More"}
              </button>
            )}
          </Table>
        </TableContainer>
        <Snackbar
          open={showCopySuccess}
          onClose={() => setShowCopySuccess(false)}
          autoHideDuration={2000}
          message="Copied email to clipboard"
        />
      </Paper>
    </div>
  );
}
