import * as React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';

function TeacherTutorial(props) {
    if(props.completed === 'y') {
        return (
            <CheckCircleOutlineIcon style={{ color:"#7AED5C"}}></CheckCircleOutlineIcon>
        );
    }
    if(props.completed === 'n') {
        return (
            <HighlightOffIcon style={{ color:"#ED655C"}}></HighlightOffIcon>
        );
    }
    else {
        return (
            <DoNotDisturbOnOutlinedIcon></DoNotDisturbOnOutlinedIcon>
        );
    }
}

export default TeacherTutorial;