import React from "react";

import { Route, Switch } from "react-router-dom";
import ChooseDatabasePage from "../../Components/DatabasePage/pages/ChooseDatabasePage/ChooseDatabasePage";
import FirestorePage from "../../Components/DatabasePage/pages/FirestorePage/FirestorePage";
import RealtimePage from "../../Components/DatabasePage/pages/RealtimePage/RealtimePage";

type DatabasePageProps = {};

const DatabasePage: React.FC<DatabasePageProps> = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        flexDirection: "column",
      }}
    >
      <Switch>
        <Route path="/database/firestore">
          <FirestorePage />
        </Route>
        <Route path="/database/realtime">
          <RealtimePage />
        </Route>
        <Route path="/">
          <ChooseDatabasePage />
        </Route>
      </Switch>
    </div>
  );
};

export default DatabasePage;
