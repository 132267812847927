import React, { useEffect, useState } from "react";
import GameRoomsTable from "../../Components/general/GameRoomsTable/GameRoomsTable";
import UsersQuizTable from "../../Components/general/UsersQuizTable/UsersQuizTable";
import { db, realtimeDb } from "../../utils/firebase";

import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import Dashboard from "../../Components/QuizzesPage/Dashboard/Dashboard";
import UserPage from "../../Components/QuizzesPage/UserPage/UserPage";

import styles from "./QuizzesPage.module.css";
import {
  getFilteredUsers,
  getTotalQuizGamesPlayed,
  getTotalTeachersJoinedToday,
  getTotalTeachersPlayingQuizzes,
  getTotalUsersWhoOwnATeam,
} from "../../utils/Helpers";
import GameRoomPage from "../../Components/general/GameRoomPage/GameRoomPage";
import { CircularProgress } from "@mui/material";

export default function QuizzesPage({}) {
  const [totalTeachersJoinedToday, setTotalTeachersJoinedToday] = useState();
  const [totalTeachersPlayingQuizzes, setTotalTeachersPlayingQuizzes] =
    useState();
  const [totalQuizGamesPlayed, setTotalQuizGamesPlayed] = useState();
  const [totalUsersWhoHaveATeam, setTotalUsersWhoHaveATeam] = useState();

  // const [gameRooms, setGameRooms] = useState([]);
  // const [quizzes, setQuizzes] = useState();

  // useEffect(() => {
  //   console.log("in quizzespage");
  //   if (!users || users.length == 0) {
  //     return;
  //   }

  //   const main = async () => {
  //     try {
  //       const gameRoomsSnapshot = await realtimeDb.ref("gameRooms").get();
  //       const gameRoomsDict = gameRoomsSnapshot.val();
  //       const gameRoomsArray = Object.keys(gameRoomsDict).map((key) => {
  //         return {
  //           id: key,
  //           ...gameRoomsDict[key],
  //         };
  //       });
  //       setGameRoomsDict(gameRoomsDict);
  //       setGameRooms(gameRoomsArray.reverse());

  //       const quizzesQuerySnapshot = await db.collection("quizzes").get();
  //       let quizzesLocal = {};
  //       for (const doc of quizzesQuerySnapshot.docs) {
  //         quizzesLocal[doc.id] = doc.data();
  //       }
  //       setQuizzes(quizzesLocal);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   main();
  // }, []);
  let match = useRouteMatch();

  useEffect(() => {
    const main = async () => {
      const totalTeachersJoinedToday = await getTotalTeachersJoinedToday();
      const totalTeachersPlayingQuizzes =
        await getTotalTeachersPlayingQuizzes();
      const totalQuizGamesPlayed = await getTotalQuizGamesPlayed();

      const totalUsersWhoHaveATeam = await getTotalUsersWhoOwnATeam();

      setTotalTeachersJoinedToday(totalTeachersJoinedToday);
      setTotalTeachersPlayingQuizzes(totalTeachersPlayingQuizzes);
      setTotalQuizGamesPlayed(totalQuizGamesPlayed);

      setTotalUsersWhoHaveATeam(totalUsersWhoHaveATeam);
    };

    main();

    // setHasLoadedGlobalNumbers(true);
  }, []);

  return (
    <div
      style={{
        padding: "0px 40px",
        overflow: "scroll",
        display: "flex",
        flex: 1,
        width: "100%",
        flexDirection: "column",
      }}
    >
      <>
        <div
          style={{
            display: "flex",
            width: "100%",
            // flex: 1,
            position: "relative",
          }}
        >
          <div className={styles.dataDisplay}>
            <div className={styles.value} style={{ backgroundColor: "blue" }}>
              {totalTeachersJoinedToday}
            </div>
            <div className={styles.subText}>
              Teachers joined
              <br />
              today
            </div>
          </div>

          <div className={styles.dataDisplay}>
            <div className={styles.value}>{totalTeachersPlayingQuizzes}</div>
            <div className={styles.subText}>
              Teachers
              <br />
              played games
              <br />
              since launch
            </div>
          </div>

          <div className={styles.dataDisplay}>
            <div className={styles.value} style={{ backgroundColor: "red" }}>
              {totalQuizGamesPlayed}
            </div>
            <div className={styles.subText}>
              Games played
              <br />
              since launch
            </div>
          </div>

          <div style={{ marginTop: "20px" }}>
            <div className={styles.dataDisplay}>
              <div
                className={styles.value}
                style={{ backgroundColor: "darkgreen" }}
              >
                {totalUsersWhoHaveATeam}
              </div>
              <div className={styles.subText}>
                Teachers
                <br />
                who have a team
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            overflow: "scroll",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <Switch>
            <Route exact path={`${match.path}/gameRoom/:gameRoomId/`}>
              <GameRoomPage />
            </Route>
            <Route path={`${match.path}`}>
              <Dashboard />
            </Route>
          </Switch>
        </div>
      </>
    </div>
  );
}
