import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { FirestoreCollectionName } from "../../../../types/FirestoreCollectionName";
import { downloadCollection } from "../../utils/downloadCollection";
import CustomFunctionsHomePage from "./pages/CustomFunctionsHomePage/CustomFunctionsHomePage";

type CustomFunctionsPageProps = {};

const CustomFunctionsPage: React.FC<CustomFunctionsPageProps> = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Switch>
        <Route exact path="/database/firestore/custom-functions">
          <CustomFunctionsHomePage />
        </Route>
        <Route path="/">
          <CustomFunctionsHomePage />
        </Route>
      </Switch>
    </div>
  );
};

export default CustomFunctionsPage;
