import { functions } from "../../../../../utils/firebase";

export const fetchTeams = async () => {
  const cleanupRealtimeDbTeamsCollabUpdates = functions.httpsCallable(
    "cleanupRealtimeDbTeamsCollabUpdates",
    { timeout: 540000 }
  );
  try {
    const result = await cleanupRealtimeDbTeamsCollabUpdates();
    console.log("result", result);
  } catch (error) {
    console.error("Error calling Firebase function:", error);
    throw error;
  }
};
