import * as React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import FaceIcon from "@mui/icons-material/Face";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SchoolIcon from "@mui/icons-material/School";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
//added
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

//imported icons
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function IconChips(props) {
  // if (props.name === "student") {
  //   return (
  //     <Stack direction="row" spacing={1}>
  //       <Chip icon={<SchoolIcon />} label={props.name} />
  //     </Stack>
  //   );
  // }
  // if (props.name === "teacher") {
  //   return (
  //     <Stack direction="row" spacing={1}>
  //       <Chip icon={<AccountBalanceIcon />} label={props.name} />
  //     </Stack>
  //   );
  // }
  // if (props.name === "consumer") {
  //   return (
  //     <Stack direction="row" spacing={1}>
  //       <Chip icon={<ShoppingCartIcon />} label={props.name} />
  //     </Stack>
  //   );
  // }
    if (props.name === "student") {
    return (
      <Stack direction="row" spacing={1}>
        <Chip icon={<SchoolIcon />} style={{backgroundColor:'#BAE0FA'}} label={props.name} />
      </Stack>
    );
  }
  else if (props.name === "teacher") {
    return (
      <Stack direction="row" spacing={1}>
        <Chip icon={<AccountBalanceIcon />} style={{backgroundColor:'#FAE1BA'}} label={props.name} />
      </Stack>
    );
  }
  else if (props.name === "consumer") {
    return (
      <Stack direction="row" spacing={1}>
        <Chip icon={<ShoppingCartIcon />} style={{backgroundColor:'#FABAD9'}} label={props.name} />
      </Stack>
    );
  }
  else if (props.name === "amazon") {
    return (
      <Stack direction="row" spacing={1}>
        <Chip icon={<ShoppingBasketIcon />} style={{backgroundColor:'#C3BAFA'}} label={props.name} />
      </Stack>
    )
  }
}

// function CompleteTutorial() {
//   if(props.name === 'teacher') {
//     if(props.completed === 'y') {
//       return (
//         <CheckCircleOutlineIcon></CheckCircleOutlineIcon>
//       );
//     }
//     else {
//       return (
//         <HighlightOffIcon></HighlightOffIcon>
//       );
//     }
//   }
// }

export default IconChips;
