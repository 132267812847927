import React from "react";
import { Link } from "react-router-dom";

type ChooseUtilityPageProps = {};

const ChooseUtilityPage: React.FC<ChooseUtilityPageProps> = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Link to="/database/firestore/backup">
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "dodgerblue",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid blue",
            display: "inline-block",
            cursor: "pointer",
            margin: "10px",
          }}
        >
          Backup data
        </div>
      </Link>
      <Link to={"/database/firestore/visualize"}>
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "dodgerblue",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid blue",
            display: "inline-block",
            cursor: "pointer",
            margin: "10px",
          }}
        >
          Visualize data
        </div>
      </Link>

      <Link to={"/database/firestore/update"}>
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "dodgerblue",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid blue",
            display: "inline-block",
            cursor: "pointer",
            margin: "10px",
          }}
        >
          Update data
        </div>
      </Link>

      <Link to={"/database/firestore/custom-functions"}>
        <div
          style={{
            padding: "10px 20px",
            fontSize: "20px",
            backgroundColor: "dodgerblue",
            color: "white",
            borderRadius: "5px",
            borderBottom: "3px solid blue",
            display: "inline-block",
            cursor: "pointer",
            margin: "10px",
          }}
        >
          Custom Functions
        </div>
      </Link>
    </div>
  );
};

export default ChooseUtilityPage;
