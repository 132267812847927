import { db } from "./firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";

const createGameRoomMap = (gameRooms) => {
  const gameRoomMap = {};
  gameRooms.forEach((gameRoom) => {
    if (!gameRoomMap[gameRoom.teacherId]) {
      gameRoomMap[gameRoom.teacherId] = [];
    }
    gameRoomMap[gameRoom.teacherId].push(gameRoom);
  });
  return gameRoomMap;
};

const createQuizzesMap = (quizzes) => {
  const quizzesMap = {};
  for (const quizId in quizzes) {
    const quiz = quizzes[quizId];
    if (!quizzesMap[quiz.userId]) {
      quizzesMap[quiz.userId] = [];
    }
    quizzesMap[quiz.userId].push(quiz);
  }
  return quizzesMap;
};

const getQuizzesForUserWithId = (userId, gameRooms) => {
  const userQuizzes = [];
  for (const gameRoom of gameRooms) {
    if (gameRoom.teacherId == userId) {
      userQuizzes.push(gameRoom);
    }
  }
  return userQuizzes;
};

const getCreatedQuizzesForUser = (userId, quizzes) => {
  const userQuizzes = [];
  for (const quizId in quizzes) {
    const quizObj = quizzes[quizId];
    if (quizObj.userId == userId) {
      userQuizzes.push(quizObj);
    }
  }
  return userQuizzes;
};

const getQuizzesInfo = (quizzes, generalQuizzesDict) => {
  let totalQuizzesStarted = 0;
  let lastSeen;
  let startedQuizzesList = [];
  let startedQuizzesCourseId = [];
  let createdQuizzesList = [];
  let quizTypesList = [];

  for (const quiz of quizzes) {
    const quizDictEntry = generalQuizzesDict[quiz.quizId];
    const quizName = quizDictEntry ? quizDictEntry.name : "";
    quizTypesList.push(quiz ? quiz.type : "");
    createdQuizzesList.push(quizName);

    if (quiz.updateTimeInMilliseconds != null) {
      startedQuizzesList.push(quizName);
      startedQuizzesCourseId.push(
        quizDictEntry
          ? {
              courseId: quizDictEntry.courseId,
              userId: quizDictEntry.userId,
            }
          : {}
      );
      totalQuizzesStarted += 1;
      lastSeen = Math.max(
        lastSeen || 0,
        quiz.updateTimeInMilliseconds,
        quiz.createdAt
      );
    }
  }

  return {
    totalQuizzesStarted,
    lastSeen: lastSeen ? new Date(lastSeen) : undefined,
    startedQuizzesList,
    startedQuizzesCourseId,
    createdQuizzesList,
    quizTypesList,
  };
};

export const getFilteredUsers = (users, gameRooms, quizzes) => {
  const gameRoomMap = createGameRoomMap(gameRooms);
  const quizzesMap = createQuizzesMap(quizzes);
  const march15_2023 = new Date("2023-03-15T00:00:00");

  let newUsers = users
    .filter((user) => user.isTeacher)
    .map((user) => {
      const myQuizzesPlayed = gameRoomMap[user.id] || [];
      const customCreatedQuizzes = quizzesMap[user.id] || [];
      const createdAtDate = new Date(user.createdAt.toDate());

      const {
        totalQuizzesStarted,
        lastSeen,
        startedQuizzesList,
        startedQuizzesCourseId,
        createdQuizzesList,
        quizTypesList,
      } = getQuizzesInfo(myQuizzesPlayed, quizzes);

      return {
        id: user.id,
        subscriptionStatus: user.subscriptionStatus
          ? user.subscriptionStatus
          : user.stripeId
          ? "Clicked trial"
          : "Not subscribed",
        hasStartedQuiz: totalQuizzesStarted > 0,
        adminName: user.adminName,
        adminEmail: user.adminEmail,
        name: user.name,
        email: user.email,
        emailIsReal: user.emailIsReal,
        neverBounceResponse: user.neverBounceResponse,
        totalQuizGamesCreated: myQuizzesPlayed.length,
        totalQuizzesStarted,
        customCreatedQuizzes,
        createdQuizzesList,
        startedQuizzesList,
        quizTypesList,
        startedQuizzesCourseId,
        viewProductOrStartTutorialsClickedTime:
          user.viewProductOrStartTutorialsClickedTime,
        viewProductOrStartTutorialsProjectId:
          user.viewProductOrStartTutorialsProjectId,
        learnMoreAboutProductClickedTime: user.learnMoreAboutProductClickedTime,
        learnMoreAboutProductProjectId: user.learnMoreAboutProductProjectId,
        lastSeen: lastSeen ? new Date(lastSeen) : createdAtDate,
      };
    });

  newUsers.sort((a, b) => {
    if (!a.lastSeen) return 1;
    if (!b.lastSeen) return -1;
    return b.lastSeen - a.lastSeen;
  });

  return newUsers;
};

export const getTotalTeachersJoinedToday = async () => {
  // Assuming you're using Firebase with JavaScript and this code is inside an async function
  const todayStart = new Date();
  todayStart.setHours(0, 0, 0, 0); // Set to the start of today

  const todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999); // Set to the end of today

  // Convert these JavaScript Dates to Firestore Timestamps
  const todayStartTimestamp = firebase.firestore.Timestamp.fromDate(todayStart);
  const todayEndTimestamp = firebase.firestore.Timestamp.fromDate(todayEnd);

  try {
    const snapshot = await db
      .collection("users")
      .where("isTeacher", "==", true) // Filters for teachers
      .where("createdAt", ">=", todayStartTimestamp) // Created today or later
      .where("createdAt", "<=", todayEndTimestamp) // But not after today
      .get();

    const totalTeachersJoinedToday = snapshot.size; // The number of documents that match the query
    console.log("totalTeachersJoinedToday", totalTeachersJoinedToday);
    return totalTeachersJoinedToday;
  } catch (error) {
    console.error("Error getting documents: ", error);
  }
};

export const getTotalQuizGamesPlayed = async () => {
  const doc = await db.collection("globalNumbers").doc("quizzes").get();

  const totalTeachersPlaying = doc.data().totalQuizzesPlayed;
  return totalTeachersPlaying;
};

export const getTotalTeachersPlayingQuizzes = async () => {
  const doc = await db.collection("globalNumbers").doc("quizzes").get();

  const totalTeachersPlaying = doc.data().totalTeachersPlaying;
  return totalTeachersPlaying;
};

export const getTotalUsersWhoOwnATeam = async () => {
  const doc = await db.collection("globalNumbers").doc("teams").get();

  const totalUsersWhoOwnATeam = doc.data().totalUsersWhoOwnATeam;
  return totalUsersWhoOwnATeam;
};
