import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./IDE.module.css";

import CodeEditor from "./components/CodeEditor/CodeEditor";
import { Chip } from "@mui/material";

export default function IDE({
  id,
  interpreter,
  isExercise = false,
  defaultCode = "",
  walkThroughTutorial,
  readOnly = false,
  runnable,
  displayOutputShell,
  totalEditorLines = 10,
  totalOutputLines = 10,
  marginTop,
  marginBottom,
  labels,
  showFlashButton,
  isPractice,
}) {
  const [code, setCode] = useState(defaultCode);

  const codeRef = useRef(defaultCode);

  const isMounted = useRef(false);

  codeRef.current = code;

  const getCodeEditor = (solutionCodeEditor = false) => {
    return (
      <CodeEditor
        id={solutionCodeEditor ? null : id}
        // interpreter={interpreter}
        // interpreterContext={interpreterContext}
        readOnly={solutionCodeEditor ? true : readOnly}
        code={code}
        setCode={setCode}
        // onChange={onChange}
        defaultCode={defaultCode}
        runnable={runnable}
        totalEditorLines={totalEditorLines}
        totalOutputLines={totalOutputLines}
        displayOutputShell={displayOutputShell}
        marginTop={marginTop}
        marginBottom={marginBottom}
        walkThroughTutorial={walkThroughTutorial}
        labels={labels}
        showFlashButton={showFlashButton}
      />
    );
  };

  return (
    <div className={`${styles.root} ${isExercise ? styles.exercise : ""}`}>
      <div style={{ position: "relative" }}>{getCodeEditor()}</div>
    </div>
  );
}
