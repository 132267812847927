// import React, { lazy, Suspense } from 'react';
import React, { lazy, Suspense } from "react";
// import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

// import { Link } from "react-router-dom";

import ProgressBar from "./ProgressBar";
import IconChips from "./TypePerson.js";
import TeacherTutorial from "./TeacherTutorial";
//added
import ControlledCheckbox from "./ControlledCheckbox";
import firebase, { db, auth } from "../utils/firebase";
import { useState, useEffect } from "react";

//added
// import { ref, set, get, update, remove, child} from "firebase/database";
import DeleteButtonAlert from "./DeleteButtonAlert";
import { Link } from "react-router-dom";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import ControlledCheckboxRealEmail from "./ControlledCheckboxRealEmail";
import { splitName } from "../utils/Constants";
import EditUserButtonAlert from "./EditUserButtonAlert";
// import { Inventory } from "@mui/icons-material";

//added for lazy loading

// const MyComponent = lazy(() => import('./MyComponent'));

function DashboardTableContents({
  users,
  usersProgress,
  classroomUsersCountDict,
  showConsumers,
}) {
  //   function deleteData() {
  //     // const db = this.state.db;
  //     // const data = this.getAllInputs();

  //     //remove(ref(db, 'Customer/'+data.name))
  //     alert('r u trying to delete???');
  //     // .catch(error)=>{alert("there was an error, details: "+error)});
  // }

  // function sendAlert() {
  //     alert('r u trying to delete???');
  // }

  const [updatedUsers, setUpdatedUsers] = useState([]);

  console.log("usersProgressInDashboardTableContents", usersProgress);

  const getTotalStudentsFromClassrooms = (classrooms) => {
    let totalStudents = 0;
    for (const classroom of classrooms) {
      if (classroomUsersCountDict[classroom]) {
        totalStudents += classroomUsersCountDict[classroom];
      }
    }
    return totalStudents;
  };

  const headers = [
    "Name",
    "Email",
    "Email bounces",
    "NeverBounce",
    "Followed Up",
    "Type",
    "Classroom ID",
    "Completed Tutorial",
    "Sign up Date",
    "Last Seen In Tutorials",
    "Smart Doorbell Progress",
    "Ping Pong Launcher Progress",
    "Clicked For More Info",
    "Clicked For More Info ProjectId",
  ];

  const generateCSVData = () => {
    const csvData = [
      headers,
      ...updatedUsers.map((row) => [
        row.adminName ? row.adminName : row.name,
        row.adminEmail ? row.adminEmail : row.email,
        !row.emailIsReal,
        row.emailIsReal != null && !row.emailIsReal
          ? "invalid"
          : row.neverBounceResponse,
        row.followedUp,
        row.isAmazon
          ? "amazon"
          : row.isTeacher
          ? "teacher"
          : row.isConsumer
          ? "consumer"
          : "student",
        row.myClassrooms ? row.myClassrooms[0] : "",
        row.showFinalWalkthroughPopup == false ? "y" : "n",
        row.createdAt ? row.createdAt.toDate().toISOString() : "",
        row.lastSeenInTutorials ? row.lastSeenInTutorials.toISOString() : "",
        parseInt(row.smartDoorbellProgress),
        parseInt(row.pingPongLauncherProgress),
        row.viewProductOrStartTutorialsClickedTime ||
        row.learnMoreAboutProductClickedTime
          ? "y"
          : "n",
        row.viewProductOrStartTutorialsProjectId
          ? row.viewProductOrStartTutorialsProjectId
          : row.learnMoreAboutProductProjectId
          ? row.learnMoreAboutProductProjectId
          : "",
      ]),
    ];
    return csvData;
  };

  const downloadCSV = () => {
    const csvData = generateCSVData();
    const csvContent = Papa.unparse(csvData);
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "teacher-data.csv");
  };

  useEffect(() => {
    if (!users || !usersProgress) {
      return;
    }

    let filteredUsersLocal = [];

    for (const user of users) {
      if (user.email == "ajksfhad@asldkfj.com") {
        console.log("this is the user", user);
      }
      // it must be a teacher that has either made progress on one of the projects or has added students to their class
      if (!showConsumers) {
        if (
          !user.viewProductOrStartTutorialsClickedTime &&
          !(
            user.isTeacher &&
            (getTotalStudentsFromClassrooms(
              user.myClassrooms ? user.myClassrooms : []
            ) > 0 ||
              (usersProgress[user.id] &&
                usersProgress[user.id]["smart-doorbell"] &&
                Object.keys(usersProgress[user.id]["smart-doorbell"]).length >
                  0) ||
              (usersProgress[user.id] &&
                usersProgress[user.id]["ping-pong-launcher"] &&
                Object.keys(usersProgress[user.id]["ping-pong-launcher"])
                  .length > 0))
          )
        ) {
          continue;
        }
      } else {
        // if they havent enrolled then get out
        if (!(user.enrolledProjects && user.enrolledProjects.length > 0)) {
          continue;
        }
        if (user.enrolledClassrooms && user.enrolledClassrooms.length > 0) {
          continue;
        }
      }

      const smartDoorbellProgress =
        usersProgress[user.id] && usersProgress[user.id]["smart-doorbell"];
      const pplProgress =
        usersProgress[user.id] && usersProgress[user.id]["ping-pong-launcher"];

      let mostRecentDate =
        user && user.createdAt ? user.createdAt.toDate() : new Date();
      for (let key in smartDoorbellProgress) {
        // Convert the Firebase Timestamp to a JavaScript Date object
        console.log("smartDoorbellProgress[key]", smartDoorbellProgress[key]);

        try {
          let date = smartDoorbellProgress[key].toDate();

          // If mostRecentDate is not set or date is more recent, update mostRecentDate
          if (!mostRecentDate || date > mostRecentDate) {
            mostRecentDate = date;
          }
        } catch (error) {
          console.log("unable to convert to time");
        }
      }
      for (let key in pplProgress) {
        try {
          // Convert the Firebase Timestamp to a JavaScript Date object
          let date = pplProgress[key].toDate();
          // If mostRecentDate is not set or date is more recent, update mostRecentDate
          if (!mostRecentDate || date > mostRecentDate) {
            mostRecentDate = date;
          }
        } catch (error) {
          console.log("unable to convert to time");
        }
      }

      filteredUsersLocal.push({
        data: user,
        id: user.id,
        name: user.name,
        adminName: user.adminName,
        adminEmail: user.adminEmail,
        email: user.email,
        emailIsReal: user.emailIsReal,
        neverBounceResponse:
          user.emailIsReal != null && !user.emailIsReal
            ? "invalid"
            : user.neverBounceResponse,
        followedUp: user.followedUp,
        isAmazon: user.isAmazon,
        isTeacher: user.isTeacher,
        totalStudentsEnrolled: user.isTeacher
          ? getTotalStudentsFromClassrooms(
              user.myClassrooms ? user.myClassrooms : []
            )
          : 0,
        isConsumer: user.isConsumer,
        myClassrooms: user.myClassrooms,
        showFinalWalkthroughPopup: user.showFinalWalkthroughPopup,
        createdAt: "createdAt" in user ? user.createdAt : null,
        lastSeenInTutorials: mostRecentDate,
        smartDoorbellProgress:
          usersProgress[user.id] && usersProgress[user.id]["smart-doorbell"]
            ? (Object.keys(usersProgress[user.id]["smart-doorbell"]).length /
                75) *
              100
            : 0,
        pingPongLauncherProgress:
          usersProgress[user.id] && usersProgress[user.id]["ping-pong-launcher"]
            ? (Object.keys(usersProgress[user.id]["ping-pong-launcher"])
                .length /
                50) *
              100
            : 0,
        viewProductOrStartTutorialsClickedTime:
          !!user.viewProductOrStartTutorialsClickedTime,
        viewProductOrStartTutorialsProjectId:
          user.viewProductOrStartTutorialsProjectId,
        learnMoreAboutProductClickedTime:
          !!user.learnMoreAboutProductClickedTime,
        learnMoreAboutProductProjectId: user.learnMoreAboutProductProjectId,
      });
    }
    setUpdatedUsers(filteredUsersLocal);
  }, [users, usersProgress]);

  console.log("updatedUsers", updatedUsers);

  return (
    <TableBody>
      <a
        style={{
          position: "absolute",
          top: "-50px",
          right: "30px",
          backgroundColor: "green",
          borderRadius: "10px",
          padding: "10px 20px",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => downloadCSV()}
      >
        Download CSV
      </a>
      {updatedUsers.map((user) => (
        <>
          {
            <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
              <TableCell>
                {<DeleteButtonAlert userRef={user.id} userName={user.name} />}
              </TableCell>
              <TableCell>
                {
                  <EditUserButtonAlert
                    userRef={user.id}
                    userName={user.adminName ? user.adminName : user.name}
                    userEmail={user.adminEmail ? user.adminEmail : user.email}
                    onUpdate={(newName, newEmail) =>
                      setUpdatedUsers((prevState) => {
                        return prevState.map((userPrevState) => {
                          if (userPrevState.id === user.id) {
                            return {
                              ...userPrevState,
                              adminName: newName,
                              adminEmail: newEmail,
                            };
                          }
                          return userPrevState;
                        });
                      })
                    }
                  />
                }
              </TableCell>
              <TableCell>
                <Link to={"/user/" + user.id}>
                  {(user.adminName || user.name) &&
                    splitName(user.adminName ? user.adminName : user.name)
                      .firstName}
                </Link>
              </TableCell>
              <TableCell>
                <Link to={"/user/" + user.id}>
                  {(user.adminName || user.name) &&
                    splitName(user.adminName ? user.adminName : user.name)
                      .lastName}
                </Link>
              </TableCell>
              <TableCell>
                {user.adminEmail ? user.adminEmail : user.email}
              </TableCell>
              <TableCell
              // onClick={(e) => {
              //   e.stopPropagation();
              //   e.preventDefault();
              // }}
              >
                <ControlledCheckboxRealEmail
                  value={
                    user.emailIsReal != null
                      ? !user.emailIsReal
                      : user.neverBounceResponse == "invalid"
                  }
                  onChange={async (newVal) => {
                    const emailIsReal = !newVal;
                    await db.collection("users").doc(user.id).update({
                      emailIsReal: emailIsReal,
                    });

                    setUpdatedUsers((prevState) => {
                      return prevState.map((userPrevState) => {
                        if (userPrevState.id === user.id) {
                          return {
                            ...userPrevState,
                            emailIsReal: emailIsReal,
                          };
                        }
                        return userPrevState;
                      });
                    });
                  }}
                />
              </TableCell>
              <TableCell>
                {user.emailIsReal != null && !user.emailIsReal
                  ? "invalid"
                  : user.neverBounceResponse}
              </TableCell>
              <TableCell>{<ControlledCheckbox user={user} />}</TableCell>
              <TableCell>
                {
                  <IconChips
                    name={
                      user.isAmazon
                        ? "amazon"
                        : user.isTeacher
                        ? "teacher"
                        : user.isConsumer
                        ? "consumer"
                        : "student"
                    }
                  />
                }
              </TableCell>
              <TableCell>
                {user.myClassrooms ? user.myClassrooms[0] : ""}
              </TableCell>
              <TableCell>{user.totalStudentsEnrolled}</TableCell>
              <TableCell>
                {
                  <TeacherTutorial
                    completed={
                      user.showFinalWalkthroughPopup == false ? "y" : "n"
                    }
                  />
                }
              </TableCell>
              <TableCell>
                {user.createdAt ? user.createdAt.toDate().toDateString() : null}
              </TableCell>
              <TableCell>
                {user.lastSeenInTutorials
                  ? user.lastSeenInTutorials.toDateString()
                  : null}
              </TableCell>
              <TableCell>
                {<ProgressBar amount={user.smartDoorbellProgress} />}
              </TableCell>
              <TableCell>
                {<ProgressBar amount={user.pingPongLauncherProgress} />}
              </TableCell>
              <TableCell>
                {user.viewProductOrStartTutorialsClickedTime ||
                user.learnMoreAboutProductClickedTime
                  ? "Yes"
                  : "No"}
              </TableCell>
              <TableCell>
                {user.viewProductOrStartTutorialsProjectId
                  ? user.viewProductOrStartTutorialsProjectId
                  : user.learnMoreAboutProductProjectId
                  ? user.learnMoreAboutProductProjectId
                  : ""}
              </TableCell>
            </TableRow>
          }
        </>
      ))}
    </TableBody>
  );
}

export default DashboardTableContents;
