import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import firebase, { db, auth } from "../utils/firebase";

function ControlledCheckboxRealEmail({ value, onChange }) {
  const handleChange = async (event) => {
    // alert("hi");
    event.stopPropagation();
    event.preventDefault();

    let newVal = event.target.checked;

    console.log("newVal" + event.target.checked);

    if (onChange) {
      onChange(newVal);
    }

    // db.collection('users').doc(user.id).field('followedUp').set(true);
  };

  console.log("currentValue", value);

  return (
    <Checkbox
      checked={!!value}
      onChange={handleChange}
      // onClick={(e) => {
      //   e.stopPropagation();
      //   e.preventDefault();
      // }}
      inputProps={{ "aria-label": "controlled" }}
    />
  );
}

export default ControlledCheckboxRealEmail;
