import React, { useEffect, useState } from "react";
import { db, realtimeDb } from "../../utils/firebase";
import { CircularProgress } from "@mui/material";
import WeeklyMoversTable from "../../Components/WeeklyMoversTable";
import DashboardTable from "../../Components/DashboardTable";

export default function CodingKitsPage() {
  const [users, setUsers] = useState([]); //will only set the empty array once at the very beginning. dont need to do this more than once sister.
  const [usersProgress, setUsersProgress] = useState({});

  const [classroomUsersCountDict, setClassroomUsersCountDict] = useState({});

  const [hasLoadedUsersData, setHasLoadedUsersData] = useState(false);

  const [userIdsDict, setUserIdsDict] = useState({});

  const callEmailVerificationFunction = async (userId, emailAddress) => {
    // const functionURL = functions._url("emailVerification"); // NOTE: this usage of _url is a bit hacky and isn't an official method.
    // const functionURL =
    //   "https://us-central1-juicemind-a9d9e.cloudfunctions.net/emailVerification";

    try {
      const functionURL =
        "https://us-central1-juicemind-a9d9e.cloudfunctions.net/emailVerification";

      // try {
      const response = await fetch(functionURL, {
        method: "POST",
        body: JSON.stringify({
          userId: userId,
          email_address: emailAddress,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);
      return data;
    } catch (error) {
      console.error("Error calling cloud function:", error);
    }
  };

  async function GetUserData() {
    try {
      console.log("Start pulling data");
      // First query: Get documents with the "createdAt" field and order them.
      const withCreatedAtSnapshot = await db
        .collection("users")
        // .where("createdAt", "!=", null) // Filtering documents that have the createdAt field
        .orderBy("createdAt", "desc")
        .get();

      // 2. Fetch all documents (assuming the number of docs is manageable; otherwise, pagination is required).
      // const allDocsSnapshot = await db.collection("users").get();

      // Filter out documents that don't have the 'createdAt' field
      // let withoutCreatedAtDocs = allDocsSnapshot.docs.filter(
      //   (doc) => !doc.data().createdAt
      // );

      // Merge the results
      // const allDocs = [...withCreatedAtSnapshot.docs, ...withoutCreatedAtDocs];
      const allDocs = [...withCreatedAtSnapshot.docs];

      console.log("Finished pulling data");

      let userIdsDictLocal = {};
      var items = [];
      let classroomUsersCountDictLocal = {};

      let emails = {};

      let total = 0;

      for (let doc of allDocs) {
        console.log("doc.data()", doc.data());
        // maybe this way of filtering out duplicates is bad cause you'll get inconsitent neverbounce info. but should be ok cause we're ordering the users so should be same user every time.
        if (doc.data().email == "ajksfhad@asldkfj.com") {
          console.log("YO WHATUP");
        }
        if (doc.data().email in emails) {
          console.log("dup email", doc.data().email);
        }

        if (doc.data().name !== "John Doe" && !(doc.data().email in emails)) {
          emails[doc.data().email] = true;
          items.push({
            id: doc.id,
            ...doc.data(),
            // neverBounceResponse: !doc.data().emailIsReal
            //   ? "invalid"
            //   : doc.data().neverBounceResponse,

            date: doc.data().createdAt,
          });
          userIdsDictLocal[doc.id] = doc.data();
          setUserIdsDict(userIdsDictLocal);

          console.log("doc.data()", "hi");
          if (!("neverBounceResponse" in doc.data())) {
            // await callEmailVerificationFunction(doc.id, doc.data().email);
            total += 1;
          }

          console.log("doc.data()", "yo");

          if (doc.data().enrolledClassrooms) {
            for (const classroomId of doc.data().enrolledClassrooms) {
              if (classroomUsersCountDictLocal[classroomId]) {
                classroomUsersCountDictLocal[classroomId] += 1;
              } else {
                classroomUsersCountDictLocal[classroomId] = 1;
              }
            }
          }
        }
      }
      console.log("total", total);
      setUsers(items);
      setClassroomUsersCountDict(classroomUsersCountDictLocal);

      console.log("before the catch");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  async function GetProgressData() {
    try {
      const querySnapshotProgress = await db.collection("progress").get();
      let usersProgressLocal = {};
      querySnapshotProgress.forEach((doc) => {
        if (doc.data().userId && doc.data().projectId) {
          let dict = usersProgressLocal[doc.data().userId]
            ? usersProgressLocal[doc.data().userId]
            : {};
          dict[doc.data().projectId] = doc.data().data;
          usersProgressLocal[doc.data().userId] = dict;
        }
      });
      setUsersProgress(usersProgressLocal);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    const main = async () => {
      await GetUserData();
      await GetProgressData();

      setHasLoadedUsersData(true);
    };
    main();
  }, []);

  return (
    <div>
      {!hasLoadedUsersData ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div style={{ maxWidth: 1430 }}>
          <h2>Weekly Movers</h2>
          <WeeklyMoversTable
            users={users}
            usersProgress={usersProgress}
            classroomUsersCountDict={classroomUsersCountDict}
          />
          <h2>Admin Dashboard</h2>
          <DashboardTable
            users={users}
            usersProgress={usersProgress}
            classroomUsersCountDict={classroomUsersCountDict}
          />
        </div>
      )}
    </div>
  );
}
