import React, { useEffect, useState } from "react";
import GameRoomsTable from "../../Components/general/GameRoomsTable/GameRoomsTable";
import UsersQuizTable from "../../Components/general/UsersQuizTable/UsersQuizTable";
import { db, realtimeDb } from "../../utils/firebase";

import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import Dashboard from "../../Components/QuizzesPage/Dashboard/Dashboard";
import UserPage from "../../Components/QuizzesPage/UserPage/UserPage";

import styles from "./SurveysPage.module.css";
import { getFilteredUsers } from "../../utils/Helpers";
import GameRoomPage from "../../Components/general/GameRoomPage/GameRoomPage";
import {
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  CheckCircle,
  DoNotDisturbOn,
  Leaderboard,
  LeaderboardOutlined,
} from "@mui/icons-material";

export default function SurveysPage() {
  const [users, setUsers] = useState([]);
  const [surveysStudents, setSurveysStudents] = useState([]);
  const [surveysTeachers, setSurveysTeachers] = useState([]);

  const [userIdsDict, setUserIdsDict] = useState({});

  const history = useHistory();

  const teacherHeaders = ["Name", "Email", "Rating", "Description", "Date"];
  const studentHeaders = [
    "Has Errors?",
    "User ID",
    "Description",
    "Teacher Name",
    "Game Room",
    "Date",
  ];

  useEffect(() => {
    const main = async () => {
      try {
        const usersQuerySnapshot = await db
          .collection("users")
          .orderBy("createdAt", "desc")
          .get();

        let usersLocal = [];
        let userIdsDictLocal = {};
        for (const doc of usersQuerySnapshot.docs) {
          const email = doc.data().email;
          if (email == "examplestudent@gmail.com") {
            continue;
          }

          usersLocal.push({
            id: doc.id,
            ...doc.data(),
          });
          userIdsDictLocal[doc.id] = doc.data();
        }
        setUsers(usersLocal);
        setUserIdsDict(userIdsDictLocal);

        const quizSurveysQuerySnapshot = await db
          .collection("quizSurveys")
          .orderBy("createdAt", "desc")
          .get();

        let surveysLocalTeachers = [];
        let surveysLocalStudents = [];
        for (const doc of quizSurveysQuerySnapshot.docs) {
          if (doc.data().isTeacher) {
            surveysLocalTeachers.push({ id: doc.id, ...doc.data() });
          } else {
            let data = { id: doc.id, ...doc.data() };
            if (doc.data().hasGlitches) {
              // const sentryIssues = await fetchIssuesForUser(doc.data().userId);
              // data["sentryIssues"] = sentryIssues;
            } else {
              // data["sentryIssues"] = [];
            }
            surveysLocalStudents.push(data);
          }
        }
        setSurveysTeachers(surveysLocalTeachers);
        setSurveysStudents(surveysLocalStudents);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    main();
  }, []);

  const fetchPage = async (page) => {
    const perPage = 100;
    const sentryAuthToken =
      "f9bf657b2d0c4113b01124e61f93142813bf21ea9ed94e6e955efa68281f37e3";
    const orgSlug = "juicemind";
    const projectSlug = "juicemind-react";
    const response = await fetch(
      `https://sentry.io/api/0/projects/${orgSlug}/${projectSlug}/events/?perPage=${perPage}&page=${page}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sentryAuthToken}`,
        },
      }
    );

    return response.json();
  };

  const fetchData = async (maxEvents) => {
    let allEvents = [];
    let currentPage = 1;
    let hasMore = true;

    while (hasMore) {
      const events = await fetchPage(currentPage);

      if (events.length === 0) {
        hasMore = false;
      } else {
        allEvents.push(...events);
        currentPage++;

        if (allEvents.length >= maxEvents) {
          allEvents = allEvents.slice(0, maxEvents);
          hasMore = false;
        }
      }
    }

    // Filter events starting from April 17, 2023
    const targetDate = new Date("2023-04-18T00:00:00");
    const filteredEvents = allEvents.filter((event) => {
      const eventDate = new Date(event.dateCreated);
      return eventDate >= targetDate;
    });

    return filteredEvents;
  };

  async function fetchIssuesForUser(userId) {
    const sentryAuthToken =
      "f9bf657b2d0c4113b01124e61f93142813bf21ea9ed94e6e955efa68281f37e3";
    const orgSlug = "juicemind";
    const projectSlug = "juicemind-react";
    try {
      const apiUrl = `https://sentry.io/api/0/projects/${orgSlug}/${projectSlug}/events/?query=user.id:${encodeURIComponent(
        userId
      )}`;

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${sentryAuthToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching issues: ${response.statusText}`);
      }

      const issues = await response.json();

      if (!issues) {
        return [];
      }

      console.log("issues", issues);

      const userIssues = issues.filter((issue) => {
        return (
          issue.userCount > 0 && issue.users.some((user) => user.id === userId)
        );
      });

      return userIssues;
    } catch (error) {
      console.error("Error fetching issues for user:", error);
    }
  }

  // useEffect(() => {
  //   const main = async () => {
  //     const events = await fetchData(1000);
  //     console.log("events", events);
  //   };

  //   main();
  // }, []);

  const glitchedElements = surveysStudents.filter((item) => item.hasGlitches);

  const numberOfGlitchedElements = glitchedElements.length;

  const totalRatings = surveysTeachers.reduce((accumulator, item) => {
    return accumulator + item.ratingOutOfTen;
  }, 0);

  let averageRating = totalRatings / surveysTeachers.length;
  averageRating = Math.round(averageRating * 10) / 10;

  let roundedPercentage =
    Math.round((numberOfGlitchedElements / surveysStudents.length) * 100 * 10) /
    10;

  function isBeforeOrOnSeptember8(date) {
    const inputDate = new Date(date);
    // alert(inputDate.toString());
    const checkDate = new Date(inputDate.getFullYear(), 8, 9); // 8 is September because months are 0-indexed
    // alert(checkDate.toString());

    return inputDate <= checkDate;
  }

  return (
    <>
      <div className={styles.dataDisplay}>
        <div className={styles.value} style={{ backgroundColor: "blue" }}>
          {roundedPercentage + "%"}
        </div>
        <div className={styles.subText}>
          Students had
          <br />
          errors
        </div>
      </div>
      <div className={styles.dataDisplay}>
        <div
          className={styles.value}
          style={{ backgroundColor: "blue", fontSize: "30px" }}
        >
          {averageRating + "/" + 10}
        </div>
        <div className={styles.subText}>
          Teacher
          <br />
          rating
        </div>
      </div>
      <Paper sx={{ width: "100%", position: "relative" }}>
        {/* <a
        style={{
          position: "absolute",
          top: "-50px",
          right: "30px",
          backgroundColor: "green",
          borderRadius: "10px",
          padding: "10px 20px",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => downloadCSV()}
      >
        Download CSV
      </a> */}
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {teacherHeaders.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {surveysTeachers.map((survey) => (
                <TableRow
                  // onClick={() => history.push("/quizzes/user/" + user.id)}
                  className={styles.tableRow}
                >
                  <TableCell>
                    {userIdsDict[survey.userId] &&
                      userIdsDict[survey.userId].name}
                  </TableCell>
                  <TableCell>
                    {userIdsDict[survey.userId] &&
                      userIdsDict[survey.userId].email}
                  </TableCell>
                  <TableCell>{survey.ratingOutOfTen + "/10"}</TableCell>
                  <TableCell>{survey.teacherDescription}</TableCell>
                  <TableCell>
                    {survey.createdAt
                      ? survey.createdAt.toDate().toDateString()
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Paper sx={{ width: "100%", position: "relative", marginTop: "50px" }}>
        {/* <a
        style={{
          position: "absolute",
          top: "-50px",
          right: "30px",
          backgroundColor: "green",
          borderRadius: "10px",
          padding: "10px 20px",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => downloadCSV()}
      >
        Download CSV
      </a> */}
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {studentHeaders.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {surveysStudents.map((survey) => ( */}
              {glitchedElements.map((survey) => (
                <TableRow
                  // onClick={() => history.push("/quizzes/user/" + user.id)}
                  className={styles.tableRow}
                >
                  <TableCell>
                    {survey.hasGlitches ? (
                      <Leaderboard sx={{ color: "red" }} />
                    ) : (
                      <Leaderboard />
                    )}
                  </TableCell>
                  <TableCell>
                    <a
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        let clarityUrl = `https://clarity.microsoft.com/projects/view/h0shbk9s1g/impressions?`;

                        // keep it general to the gameRoomId for instances prior to implementing clarity custom user id field
                        if (
                          !isBeforeOrOnSeptember8(survey.createdAt.toDate())
                        ) {
                          clarityUrl += `CustomUserId=is%3B${survey.userId}`;
                        }

                        clarityUrl += `&start=1693551600000&URL=2%3B2%3B${survey.gameRoomId}`;
                        window.open(clarityUrl, "_blank");
                      }}
                    >
                      {survey.userId}
                    </a>
                  </TableCell>
                  <TableCell>{survey.description}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{survey.gameRoomId}</TableCell>
                  <TableCell>
                    {survey.createdAt
                      ? survey.createdAt.toDate().toDateString()
                      : ""}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}
