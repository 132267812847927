import React from "react";
import { Switch, Route } from "react-router-dom";
import ChooseUtilityPage from "./pages/ChooseUtilityPage/ChooseUtilityPage";
import BackupDataPage from "./pages/BackupDataPage/BackupDataPage";
import CustomFunctionsPage from "./pages/CustomFunctionsPage/CustomFunctionsPage";

type FirestorePageProps = {};

const FirestorePage: React.FC<FirestorePageProps> = ({}) => {
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        flexDirection: "column",
      }}
    >
      <div style={{ fontSize: "30px", marginTop: "10px" }}>
        Firestore Admin Dashboard
      </div>

      <Switch>
        <Route exact path="/database/firestore/backup">
          <BackupDataPage />
        </Route>
        <Route exact path="/database/firestore/custom-functions">
          <CustomFunctionsPage />
        </Route>
        <Route path="/">
          <ChooseUtilityPage />
        </Route>
      </Switch>
    </div>
  );
};

export default FirestorePage;
