import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { db } from "../../utils/firebase";
import IDE from "../IDE/IDE";

export default function CodeAttempts({ userId }) {
  const [codeAttempts, setCodeAttempts] = useState([]);
  useEffect(() => {
    db.collection("codingExerciseAttempts")
      .where("userId", "==", userId)
      .orderBy("timestamp", "desc")
      .onSnapshot(function (querySnapshot) {
        let codeAttemptsLoc = [];
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            console.log(doc.data());
            codeAttemptsLoc.push(doc.data());
          });
          setCodeAttempts(codeAttemptsLoc);
        }
      });
  }, []);
  const getDateStringFromFirebaseTimestamp = (timestamp) => {
    const dateFormat = timestamp.toDate();
    return (
      dateFormat.getMonth() +
      1 +
      "/" +
      dateFormat.getDate() +
      "/" +
      dateFormat.getFullYear() +
      " " +
      dateFormat.getHours() +
      ":" +
      dateFormat.getMinutes() +
      ":" +
      dateFormat.getSeconds()
    );
  };
  return (
    <div>
      {codeAttempts.map((codeAttempt) => (
        <>
          <Typography variant="subtitle1">
            {getDateStringFromFirebaseTimestamp(codeAttempt.timestamp)}
          </Typography>
          <IDE
            readOnly={true}
            defaultCode={codeAttempt.code}
            marginTop={10}
            marginBottom={10}
          />
        </>
      ))}
    </div>
  );
}
