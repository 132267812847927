// import React, { lazy, Suspense } from 'react';
import React, { lazy, Suspense } from "react";
// import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";

// import { Link } from "react-router-dom";

import ProgressBar from "./ProgressBar";
import IconChips from "./TypePerson.js";
import TeacherTutorial from "./TeacherTutorial";
//added
import ControlledCheckbox from "./ControlledCheckbox";
import firebase, { db, auth } from "../utils/firebase";
import { useState, useEffect } from "react";

//added
// import { ref, set, get, update, remove, child} from "firebase/database";
import DeleteButtonAlert from "./DeleteButtonAlert";
import { Link } from "react-router-dom";
import ControlledCheckboxRealEmail from "./ControlledCheckboxRealEmail";
import { splitName } from "../utils/Constants";
// import { Inventory } from "@mui/icons-material";

//added for lazy loading

// const MyComponent = lazy(() => import('./MyComponent'));

function WeeklyMoversTableContents({
  users,
  usersProgress,
  classroomUsersCountDict,
  showConsumers,
}) {
  const [weeklyUsers, setWeeklyUsers] = useState([]);

  const getUserInPastWeek = (userId) => {
    if (!usersProgress[userId]) {
      return false;
    }
    const userProgressSDB = usersProgress[userId]["smart-doorbell"];
    const userProgressPPL = usersProgress[userId]["ping-pong-launcher"];

    var lastDayOfWeek = new Date(); // Creating new date object for today
    lastDayOfWeek.setDate(lastDayOfWeek.getDate() - 7); // Setting date a week back from today
    lastDayOfWeek.setHours(0, 0, 0, 0); // Setting Hour to 00:00:00:00

    let returnVal = false;
    if (userProgressSDB) {
      Object.values(userProgressSDB).forEach((value) => {
        if (value && value.toDate().getTime() >= lastDayOfWeek.getTime()) {
          console.log("return true");
          returnVal = true;
        }
      });
    }
    return returnVal;
  };

  const getTotalStudentsFromClassrooms = (classrooms) => {
    let totalStudents = 0;
    for (const classroom of classrooms) {
      if (classroomUsersCountDict[classroom]) {
        totalStudents += classroomUsersCountDict[classroom];
      }
    }
    return totalStudents;
  };

  async function GetWeeklyUsers() {
    let items = [];
    console.log("GETTING WEEKLY USERS");

    for (const user of users) {
      // const inPastWeek = usersLastCodingAttempt[user.id];
      const inPastWeek = getUserInPastWeek(user.id);
      console.log("inPastWeek", inPastWeek);
      if (inPastWeek && user.name !== "John Doe") {
        const sdbCount = usersProgress[user.id]["smart-doorbell"]
          ? Object.keys(usersProgress[user.id]["smart-doorbell"]).length
          : 0;
        const pplCount = usersProgress[user.id]["ping-pong-launcher"]
          ? Object.keys(usersProgress[user.id]["ping-pong-launcher"]).length
          : 0;
        items.push({
          id: user.id,
          data: user,
          date: user.createdAt,
          sdbProgress: (sdbCount / 75) * 100,
          pplProgress: (pplCount / 50) * 100,
          // isAmazon: amazon,
        });
      }
    }
    console.log("finished getting weekly users");
    setWeeklyUsers(items);
  }

  // useEffect(() => {
  //   //completes this once at very beginning bc has a protector called useEffect
  //   GetUsersLastCodingAttempt(); //remember you won't do this more than once. Skip to next line of code.
  // }, []);

  useEffect(() => {
    if (!users || !usersProgress) {
      console.log("users", users);
      console.log("usersProgress", usersProgress);
      // console.log("usersLastCodingAttempt", usersLastCodingAttempt);
      return;
    }
    console.log("WE'RE PAST");

    GetWeeklyUsers();
  }, [users, usersProgress]);

  //   function deleteData() {
  //     // const db = this.state.db;
  //     // const data = this.getAllInputs();

  //     //remove(ref(db, 'Customer/'+data.name))
  //     alert('r u trying to delete???');
  //     // .catch(error)=>{alert("there was an error, details: "+error)});
  // }

  // function sendAlert() {
  //     alert('r u trying to delete???');
  // }

  console.log("show consumers", weeklyUsers);

  return (
    <TableBody>
      {weeklyUsers &&
        weeklyUsers.map((user) => (
          <>
            {((!showConsumers && user.isTeacher) ||
              (showConsumers &&
                user.enrolledProjects &&
                user.enrolledProjects.length > 0 &&
                !(
                  user.enrolledClassrooms && user.enrolledClassrooms.length > 0
                ))) && (
              <TableRow hover role="checkbox" tabIndex={-1} key={user.id}>
                <TableCell>
                  <Link to={"/user/" + user.id}>
                    {splitName(user.name).firstName}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link to={"/user/" + user.id}>
                    {splitName(user.name).lastName}
                  </Link>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  {<ControlledCheckboxRealEmail user={user} />}
                </TableCell>
                <TableCell>{<ControlledCheckbox user={user} />}</TableCell>
                <TableCell>
                  {
                    <IconChips
                      name={
                        user.isAmazon
                          ? "amazon"
                          : user.isTeacher
                          ? "teacher"
                          : user.isConsumer
                          ? "consumer"
                          : "student"
                      }
                    />
                  }
                </TableCell>
                <TableCell>{user.classroomId} </TableCell>
                <TableCell>
                  {user.isTeacher
                    ? getTotalStudentsFromClassrooms(
                        user.myClassrooms ? user.myClassrooms : []
                      )
                    : 0}{" "}
                </TableCell>
                <TableCell>
                  {
                    <TeacherTutorial
                      completed={
                        user.showFinalWalkthroughPopup == false ? "y" : "n"
                      }
                    />
                  }
                </TableCell>
                <TableCell>
                  {"createdAt" in user
                    ? user.createdAt.toDate().toDateString()
                    : null}
                </TableCell>
                <TableCell>
                  {<ProgressBar amount={user.sdbProgress} />}
                </TableCell>
                <TableCell>
                  {<ProgressBar amount={user.pplProgress} />}
                </TableCell>
                <TableCell>
                  {<DeleteButtonAlert userRef={user.id} userName={user.name} />}
                </TableCell>
              </TableRow>
            )}
          </>
        ))}
    </TableBody>
  );
}

export default WeeklyMoversTableContents;
