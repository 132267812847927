import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { useState, type FormEventHandler } from "react";
import { db } from "../../../utils/firebase";

const SUBSCRIPTION_OPTIONS = [
  {
    value: "Not subscribed",
    text: "Not subscribed",
  },
  { value: "Starter", text: "Starter" },
  { value: "Silver", text: "Silver" },
  { value: "Gold", text: "Gold" },
  { value: "Diamond", text: "Diamond" },
];

function CreateSubscriptionModal({
  open,
  onClose,
  onFinish,
}: {
  open: boolean;
  onClose: () => void;
  onFinish: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState("");
  const [subscription, setSubscription] = useState<string>(
    SUBSCRIPTION_OPTIONS[0].value
  );

  const handleCreateSubscription: FormEventHandler<HTMLFormElement> = async (
    e
  ) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data = {
      chosenCourse: "dashboard",
      email: email,
      type: subscription,
      createdAt: new Date().getTime(),
    };

    await db.collection("manualSubscriptions").add(data);

    setEmail("");
    setSubscription(SUBSCRIPTION_OPTIONS[0].value);

    await onFinish?.();
    onClose?.();

    setIsSubmitting(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          background: "white",
          minWidth: 600,
          borderRadius: 8,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <div>
          <h2 style={{ textAlign: "center" }}>Create User Subscription</h2>
        </div>
        <Box
          component="form"
          onSubmit={handleCreateSubscription}
          autoComplete="off"
        >
          <TextField
            style={{ width: "100%" }}
            label="Email Address"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            fullWidth
          />
          <FormControl fullWidth sx={{ mt: 2 }}>
            {/* <InputLabel id="demo-simple-select-label">Subscription</InputLabel> */}
            <Select
              id="demo-simple-select"
              value={subscription}
              onChange={(e) => {
                setSubscription(e.target.value);
              }}
            >
              {SUBSCRIPTION_OPTIONS.map(({ text, value }) => (
                <MenuItem value={value}>{text}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            startIcon={
              isSubmitting ? <CircularProgress size={12} /> : undefined
            }
            size={"medium"}
            type="submit"
            sx={{ mt: 2 }}
          >
            Add Subscription
          </Button>
        </Box>
      </div>
    </Modal>
  );
}

export default CreateSubscriptionModal;
