import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Component } from "react";
import ReactDOM from "react-dom";
import firebase from "../utils/firebase";
import { TextField } from "@mui/material";
import { useState } from "react";

function EditUserButtonAlert(props) {
  const [open, setOpen] = useState(false);
  const [newUserFirstName, setNewUserFirstName] = useState("");
  const [newUserLastName, setNewUserLastName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserSubscriptionValue, setNewUserSubscriptionValue] = useState("");
  const [newUserPoc, setNewUserPoc] = useState("");
  const [newState, setNewState] = useState("");
  const [newDistrict, setNewDistrict] = useState("");

  const handleClickOpen = () => {
    // const { firstName, lastName } = extractNames(props?.userName || "");
    const {
      firstName = "",
      lastName = "",
      subscriptionValue = "",
      poc = "",
      state = "",
      district = "",
    } = props;
    setNewUserFirstName(firstName);
    setNewUserLastName(lastName);

    setNewUserSubscriptionValue(subscriptionValue);
    setNewUserPoc(poc);
    setNewState(state);
    setNewDistrict(district);
    setNewUserEmail(props.userEmail); // initialize with the current email
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    setOpen(false);
    firebase
      .firestore()
      .collection("users")
      .doc(props.userRef)
      .update({
        name: newUserFirstName + " " + newUserLastName,
        email: newUserEmail,
        subscriptionValue: newUserSubscriptionValue,
        poc: newUserPoc,
      })
      .then(() => {
        console.log("successfully udpated! ");
      })
      .catch((error) => {
        alert("there was an error, details: " + error);
      });

    if (!props?.userEmail) return;
    const emailDomain = props.userEmail.split("@")[1];

    if (
      !props?.state &&
      !props?.district &&
      !newState &&
      !newDistrict &&
      !emailDomain
    ) {
      props.onUpdate(newUserFirstName + " " + newUserLastName, newUserEmail);
      props?.onFinish?.();

      return;
    }

    if (!newState && !newDistrict) {
      firebase
        .firestore()
        .collection("domainToSchoolDistrictAndStateMapping")
        .doc(emailDomain)
        .delete()
        .then(() => {
          console.log("successfully deleted! ");
          props?.onUpdateStateAndDistrict?.({
            state: newState,
            district: newDistrict,
          });
          props?.onUpdate(
            newUserFirstName + " " + newUserLastName,
            newUserEmail
          );
          props?.onFinish?.();
        });

      return;
    }

    firebase
      .firestore()
      .collection("domainToSchoolDistrictAndStateMapping")
      .doc(emailDomain)
      .update({
        state: newState,
        district: newDistrict,
      })
      .then(() => {
        console.log("successfully updated! ");
        props?.onUpdateStateAndDistrict?.({
          state: newState,
          district: newDistrict,
        });
        props?.onUpdate(newUserFirstName + " " + newUserLastName, newUserEmail);
        props?.onFinish?.();
      })
      .catch((error) => {
        alert("there was an error, details: " + error);
      });
  };

  return (
    <div>
      <Button color="info" variant="outlined" onClick={handleClickOpen}>
        Edit user
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="edit-dialog-title"
        aria-describedby="edit-dialog-description"
      >
        <DialogTitle id="edit-dialog-title">Edit User</DialogTitle>
        <DialogContent>
          <DialogContentText id="edit-dialog-description">
            Edit the information for {props.userName}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="First Name"
            type="text"
            fullWidth
            value={newUserFirstName}
            onChange={(e) => setNewUserFirstName(e.target.value)}
          />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Last Name"
            type="text"
            fullWidth
            value={newUserLastName}
            onChange={(e) => setNewUserLastName(e.target.value)}
          />
          <TextField
            margin="dense"
            id="email"
            label="User Email"
            type="email"
            fullWidth
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            id="subscriptionValue"
            label="$ Value"
            type="text"
            fullWidth
            value={newUserSubscriptionValue}
            onChange={(e) => setNewUserSubscriptionValue(e.target.value)}
          />
          <TextField
            margin="dense"
            id="poc"
            label="POC"
            type="text"
            fullWidth
            value={newUserPoc}
            onChange={(e) => setNewUserPoc(e.target.value)}
          />
          {(newState || newDistrict) && (
            <>
              <TextField
                margin="dense"
                id="state"
                label="State"
                type="text"
                fullWidth
                value={newState}
                onChange={(e) => setNewState(e.target.value)}
              />
              <TextField
                margin="dense"
                id="district"
                label="District"
                type="text"
                fullWidth
                value={newDistrict}
                onChange={(e) => setNewDistrict(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditUserButtonAlert;
