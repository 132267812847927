import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import LinearWithValueLabel from "./ProgressBar";
import IconChips from "./TypePerson.js";
import CompleteTutorial from "./TeacherTutorial";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import WeeklyMoversTableHeader from "./WeeklyMoversTableHeader";
import WeeklyMoversTableContents from "./WeeklyMoversTableContents";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

function WeeklyMoversTable({
  users,
  usersProgress,
  classroomUsersCountDict,
  showConsumers,
}) {
  const [dense, setDense] = React.useState(false); //added
  const handleChangeDense = (event) => {
    //added
    setDense(event.target.checked);
  };

  return (
    <Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            size={dense ? "small" : "medium"}
          >
            <WeeklyMoversTableHeader />
            <WeeklyMoversTableContents
              users={users}
              usersProgress={usersProgress}
              classroomUsersCountDict={classroomUsersCountDict}
              showConsumers={showConsumers}
            />
          </Table>
        </TableContainer>
      </Paper>
      <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </Box>
  );
}

// function DashboardTable() {
//   return (
//     <TableContainer>
//   <Table variant='simple'>
//     <TableCaption>User Dashboard</TableCaption>
//         <DashboardTableHeaderCUI></DashboardTableHeaderCUI>
//         <DashboardTableContentCUI></DashboardTableContentCUI>
//   </Table>
// </TableContainer>
//   );
// }

export default WeeklyMoversTable;
