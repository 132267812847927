import React, {
  useCallback,
  useEffect,
  useState,
  type ChangeEvent,
  type ReactNode,
} from "react";

import { ContentCopy } from "@mui/icons-material";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  Paper,
  IconButton,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  type SelectChangeEvent,
  Modal,
} from "@mui/material";

import styles from "./UserSubscriptionTable.module.css";

import ControlledCheckboxRealEmail from "../ControlledCheckboxRealEmail";
import { db } from "../../utils/firebase";
import type { QueryDocumentSnapshot, DocumentData } from "@firebase/firestore";
import type { UserSubscription } from "./interface";

export default function UserSubscriptionsTable({
  setRefetchData,
}: {
  setRefetchData: (callback: () => void) => void;
}) {
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const [userSubscriptions, setUserSubscriptions] = useState<
    UserSubscription[]
  >([]);
  const [openNewRecordModal, setOpenNewRecordModal] = useState(false);
  const [search, setSearch] = useState("");

  const [lastVisible, setLastVisible] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true); // New state to track if more data is available

  const headers = ["ID", "Email", "chosenCourse", "Subscription"];
  const fetchData = useCallback(async () => {
    setLoading(true);
    let query = db
      .collection("manualSubscriptions")
      // .orderBy("createdAt")
      .limit(50);

    // Check if there's a search term for the email
    if (search.trim() !== "") {
      // Adjust the query to filter by exact email match
      query = db
        .collection("manualSubscriptions")
        .where("email", "==", search.trim());
    } else if (lastVisible) {
      // Apply pagination for non-search queries
      query = query.startAfter(lastVisible);
    }

    query
      .get()
      .then((querySnapshot) => {
        if (querySnapshot.docs.length < 50) {
          setHasMore(false); // If fewer documents than the limit are returned, no more data is available
        } else {
          setHasMore(true);
        }
        const lastVisibleDoc =
          querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(
          lastVisibleDoc as unknown as QueryDocumentSnapshot<DocumentData>
        );

        const items = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        if (search.trim() !== "") {
          // For searches, replace the current users list with the new results
          setUserSubscriptions(items as unknown as UserSubscription[]);
        } else {
          // For pagination, append the new results
          setUserSubscriptions((prev) => [
            ...prev,
            ...(items as unknown as UserSubscription[]),
          ]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastVisible, hasMore, search]);

  useEffect(() => {
    const main = async () => {
      setUserSubscriptions([]);
      fetchData();
    };
    main();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setRefetchData(() => fetchData);
  }, [fetchData, setRefetchData]);

  const getUserSubscriptionsDataArray = (items: UserSubscription[]) => {
    return items.map((row, index) => [
      { text: index + 1 },
      {
        isCopyable: true,
        text: row.email,
        onChange: (
          e:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string>
        ) => {},
      },
      {
        text: row.chosenCourse || "-",
        onChange: (
          e:
            | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            | SelectChangeEvent<string>
        ) => {},
      },
      {
        text: row.type ? row.type : "Not subscribed",
        selector: {
          options: [
            {
              value: row.type ? row.type : "Not subscribed",
              text: row.type ? row.type : "Not subscribed",
            },
            { value: "Starter", text: "Starter" },
            { value: "Silver", text: "Silver" },
            { value: "Gold", text: "Gold" },
            { value: "Diamond", text: "Diamond" },
          ],
          onChange: async (
            evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const newValue = evt.target.value;

            let finalPermValue: string | null = null;

            if (
              newValue.toLowerCase() === "gold" ||
              newValue.toLowerCase() === "silver" ||
              newValue.toLowerCase() === "starter" ||
              newValue.toLowerCase() === "diamond"
            ) {
              finalPermValue = newValue;
            }

            await db.collection("manualSubscriptions").doc(row.id).update({
              type: finalPermValue,
            });

            setUserSubscriptions((prevState) => {
              return prevState.map((user) => {
                if (user.id === row.id) {
                  // Check if this is the object we want to update
                  return {
                    ...user, // Copy all existing user properties
                    type: finalPermValue, // Update the specific field
                  };
                } else {
                  return user; // Return the unchanged object
                }
              });
            });
          },
        },
      },
    ]);
  };

  const copyToClipboard = async (email: string) => {
    try {
      await navigator.clipboard.writeText(email);
      setShowCopySuccess(true);
    } catch (err) {
      setShowCopySuccess(false);
    }
  };

  return (
    <>
      <button
        style={{
          position: "absolute",
          top: "-50px",
          right: "30px",
          backgroundColor: "green",
          borderRadius: "10px",
          padding: "10px 20px",
          color: "white",
          cursor: "pointer",
        }}
        onClick={() => setOpenNewRecordModal(true)}
      >
        Add New Record
      </button>

      <Paper sx={{ width: "100%", position: "relative", marginBottom: "42px" }}>
        <input
          placeholder="Search by Email"
          style={{
            width: "98%",
            border: "1px solid lightgrey",
            borderRadius: "10px",
            fontSize: "24px",
            padding: "10px",
          }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <TableContainer sx={{ maxHeight: 600 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow className={styles.tableRow}>
                {headers.map((header) => (
                  <TableCell>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {getUserSubscriptionsDataArray(userSubscriptions).map(
                (user, parentIndex) => {
                  return (
                    <TableRow key={parentIndex}>
                      {user.map(
                        ({ text, onChange, isCopyable, selector }, index) => (
                          <TableCell>
                            {headers[index + 2] === "Email bounced" ? (
                              <ControlledCheckboxRealEmail
                                value={text}
                                onChange={onChange}
                              />
                            ) : (
                              <>
                                {isCopyable && (
                                  <IconButton
                                    sx={{ marginRight: "10px" }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      copyToClipboard(text);
                                    }}
                                  >
                                    <ContentCopy sx={{ fontSize: "20px" }} />
                                  </IconButton>
                                )}

                                {selector ? (
                                  <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">
                                      Subscription
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      value={text}
                                      label="Age"
                                      onChange={
                                        selector.onChange as unknown as
                                          | ((
                                              event: SelectChangeEvent<string>,
                                              child: ReactNode
                                            ) => void)
                                          | undefined
                                      }
                                    >
                                      {selector.options.map(
                                        ({ text, value }) => (
                                          <MenuItem value={value}>
                                            {text}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </FormControl>
                                ) : (
                                  <div
                                    style={
                                      false
                                        ? {
                                            color: "blue",
                                            textDecoration: "unerline",
                                          }
                                        : {}
                                    }
                                  >
                                    {text}
                                  </div>
                                )}
                              </>
                            )}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
            {hasMore && (
              <button onClick={fetchData} disabled={loading}>
                {loading ? "Loading..." : "Load More"}
              </button>
            )}
          </Table>
        </TableContainer>
        <Snackbar
          open={showCopySuccess}
          onClose={() => setShowCopySuccess(false)}
          autoHideDuration={2000}
          message="Copied email to clipboard"
        />
        <Modal
          open={openNewRecordModal}
          onClose={() => {
            setOpenNewRecordModal(false);
          }}
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              padding: "20px",
              backgroundColor: "white",
              borderRadius: "20px",
            }}
          >
            <div style={{ marginTop: "20px" }}>test</div>
          </div>
        </Modal>
      </Paper>
    </>
  );
}
